import { StakingPool } from "../../model/stakingPool";
import StakingPoolAPYValue from "../StakingPoolAPYValue";
import React, { useState, useEffect, useRef } from "react";
import { LockedStaking, Staking } from "../../model/staking";
import { formatDate, formatUsdcLabel, isStakingPeriodEnded } from "../../utils";
import {
  useClaimAll,
  useClaimAmount,
  useClaimInterest,
  useClaimPrincipal,
  useRestake,
} from "../../helpers/contracts";

import ModalOptions from "../ModalOptions";
import { clsx } from "clsx";
import {toast} from "react-toastify";
import * as Sentry from "@sentry/react";

interface Props {
  stake: Staking | LockedStaking;
  type: StakingPool;
  onActionSuccess: () => void;
}

const ClaimableStakeCard = ({ stake, type, onActionSuccess }: Props) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isRestaking, setIsRestaking] = useState(false);
  const doClaimInterest = useClaimInterest(type, stake.id);
  const doClaimPrincipal = useClaimPrincipal(type, stake.id);
  const doClaimAll = useClaimAll(type, stake.id);
  const doClaimAmount = useClaimAmount(type, stake.id);
  const doRestake = useRestake(type, stake.id);

  const selectRef = useRef();
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
      if (isProcessing) {
          setShowMenu(false);
      }
  }, [isProcessing]);

  const onClaimInterestClick = () => {
    setIsProcessing(true);
    doClaimInterest()
        .then(() => {
            toast("Interest amount successfully claimed!", { type: 'success' });
            onActionSuccess();
        })
        .catch(error => {
          toast(error.shortMessage, { type: "error" });
          Sentry.captureException(error);
        })
        .finally(() => setIsProcessing(false));
  };

  const onClaimPrincipalClick = () => {
    setIsProcessing(true);
    doClaimPrincipal()
        .then(() => {
            toast("Principal amount successfully claimed!", { type: 'success' });
            onActionSuccess();
        })
        .catch(error => {
          toast(error.shortMessage, { type: "error" });
          Sentry.captureException(error);
        })
        .finally(() => setIsProcessing(false));
  };

  const onClaimEntireClick = () => {
    setIsProcessing(true);
    doClaimAll()
        .then(() => {
            toast("Entire amount successfully claimed!", { type: 'success' });
            onActionSuccess();
        })
        .catch(error => {
          toast(error.shortMessage, { type: "error" });
          Sentry.captureException(error);
        })
        .finally(() => setIsProcessing(false));
  };

  const onClaimCustom = (value: bigint) => {
    setIsProcessing(true);
    doClaimAmount(value)
        .then(() => {
            toast("Entered amount successfully claimed!", { type: 'success' });
            onActionSuccess();
        })
        .catch(error => {
          toast(error.shortMessage, { type: "error" });
          Sentry.captureException(error);
        })
        .finally(() => setIsProcessing(false));
  }

  const onRestakeClick = () => {
    setIsProcessing(true);
    setIsRestaking(true);
    doRestake()
        .then(() => {
            toast("Restaked successfully!", { type: 'success' });
            onActionSuccess();
        })
        .catch(error => {
          toast(error.shortMessage, { type: "error" });
          Sentry.captureException(error);
        })
        .finally(() => {
            setIsRestaking(false);
            setIsProcessing(false)
        });
  };

  const labelByType = {
    [StakingPool.LIQUIDITY]: "Liquidity Staking pool",
    [StakingPool.LOCKED_30_DAYS]: "30-day Locked Staking pool",
    [StakingPool.LOCKED_90_DAYS]: "90-day Locked Staking pool",
  };
  const isLockedPool = [
    StakingPool.LOCKED_30_DAYS,
    StakingPool.LOCKED_90_DAYS,
  ].includes(StakingPool[StakingPool[type]]);
  const isClaimable = isLockedPool ? isStakingPeriodEnded(stake as LockedStaking) : true;

  const claimBtnClasses = clsx(
      "block sm:text-xl font-normal py-2 sm:py-3 px-5 sm:px-9 rounded-full mx-auto md:mt-6",
      {
        "navButton bg-accent hover:bg-accent text-secondary mt-3": isClaimable,
        "bg-disabled text-inactive": !isClaimable
      }
  );

  const restakeBtnClasses = clsx(
      "block sm:text-xl font-normal py-2 sm:py-3 px-5 sm:px-9 rounded-full md:mt-6",
      {
          "bg-accent hover:bg-accent text-secondary": isClaimable,
          "bg-disabled text-inactive": !isClaimable
      }
  );

  const otherOptionsLabelClasses = clsx(
      "text-light text-base options-link",
      {
        "select-button text-neutral cursor-pointer": isClaimable,
        "text-locked options-link_disabled": !isClaimable,
        "before:rotate-180": showMenu
      }
  )

  useEffect(() => {
    const handler = (e) => {
      if (selectRef.current && !selectRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  });

  return (
    <div className="plain-card bg-card p-4 rounded-[15px] w-full">
      <div className="flex flex-col md:flex-row gap-4 md:gap-8">
        <h3 className="plain-card__name sm:text-[22px] text-neutral font-medium">
          {labelByType[type]}:
        </h3>
        <span className="plain-card__name sm:text-[22px] text-neutral font-medium md:ml-auto">
          {formatUsdcLabel(stake.amountToClaim)}
        </span>
        <span className="plain-card__name sm:text-[22px] text-neutral font-medium">
          APY <StakingPoolAPYValue type={type} />
        </span>
      </div>
      <div className="flex flex-col md:flex-row gap-2 md:gap-8">
        <p className="plain-card__start-date text-neutral sm:text-lg font-light mt-2">
          Start date: {formatDate(stake.created)}
        </p>
        {isLockedPool && (
          <p className="plain-card__end-date text-neutral sm:text-lg font-light mt-2">
            End date: {formatDate((stake as LockedStaking).expirationOn)}
          </p>
        )}
      </div>
      <p className="plain-card__profit sm:text-lg text-neutral font-light mt-3">
          <>
              {isLockedPool && (new Date() < new Date(Number((stake as LockedStaking).expirationOn) * 1000)) ?
                  "Claimable interest at the end: " :
                  "Claimable interest: "
              }
              {formatUsdcLabel(stake.interestToClaim, 7)}
          </>
      </p>

      {isProcessing && (
        <p className="sm:text-lg text-neutral text-center font-light">
            {isRestaking ? 'Restaking...' : 'Claiming...'}
        </p>
      )}
      {!isProcessing && (
          <div className="flex flex-col-reverse md:flex-row items-center md:items-start gap-6 justify-center">
            <div>
              <button
                className={claimBtnClasses}
                onClick={onClaimInterestClick}
                disabled={!isClaimable}
              >
                Claim interest
              </button>
              <div className="options text-center mt-2 relative">
                <span
                  id={`select-${stake.id}`}
                  ref={selectRef}
                  className={otherOptionsLabelClasses}
                  onClick={isClaimable ? () => setShowMenu(prev => !prev) : null}
                >
                  Other options
                </span>

                <ul
                  role="listbox"
                  id={`select-dropdown-`}
                  className={`absolute top-full mt-3 bg-primary rounded-[20px] text-neutral overflow-hidden w-max text-left ${
                    showMenu ? "" : "hidden"
                  }`}
                >
                  <li
                    role="option"
                    aria-selected="false"
                    className="py-3 px-[30px] hover:bg-secondary cursor-pointer"
                    onClick={onClaimInterestClick}
                  >
                    Claim interest
                  </li>
                  <li
                    role="option"
                    aria-selected="false"
                    className="py-3 px-[30px] hover:bg-secondary cursor-pointer"
                    onClick={onClaimPrincipalClick}
                  >
                    Claim principal amount
                  </li>
                  <li
                    role="option"
                    aria-selected="false"
                    className="py-3 px-[30px] hover:bg-secondary cursor-pointer"
                    onClick={onClaimEntireClick}
                  >
                    Claim entire amount
                  </li>
                  <li
                    role="option"
                    aria-selected="false"
                    className="py-3 px-[30px] hover:bg-secondary cursor-pointer"
                    onClick={() => setShowModal(true)}
                  >
                    Claim a specific amount
                  </li>
                </ul>
              </div>
              <ModalOptions
                  showModal={showModal} setShowModal={setShowModal}
                  availableToClaim={stake.amountToClaim + stake.interestToClaim}
                  onSubmit={onClaimCustom}
              />
            </div>
            {isLockedPool &&
                <div>
                  <button
                      className={restakeBtnClasses}
                      disabled={!isClaimable}
                      onClick={onRestakeClick}
                  >
                    Restake
                  </button>
                </div>
            }
          </div>
      )}
    </div>
  );
};

export default ClaimableStakeCard;
