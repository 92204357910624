import config from '../config/config';
import {useReadContract} from "wagmi";
import {erc20Abi} from "viem";


export const useUsdcBalance = (address: string) => {
    const usdcContractAddress = config.usdcTokenContractAddress;
    return useReadContract({
        address: usdcContractAddress,
        abi: erc20Abi,
        functionName: 'balanceOf',
        args: [address],
    })
}