const config = {
    walletConnectProjectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
    websiteUrl: process.env.REACT_APP_WEBSITE_URL,
    backendUrl: process.env.REACT_APP_BACKEND_URL,
    usdcTokenContractAddress: process.env.REACT_APP_USDC_CONTRACT_ADDRESS,
    liquidityPoolContractAddress: process.env.REACT_APP_LIQUIDITY_POOL_CONTRACT_ADDRESS,
    lockedPool30DaysContractAddress: process.env.REACT_APP_LOCKED_POOL_30_DAYS_CONTRACT_ADDRESS,
    lockedPool90DaysContractAddress: process.env.REACT_APP_LOCKED_POOL_90_DAYS_CONTRACT_ADDRESS,
    referralRewardsContractAddress: process.env.REACT_APP_REFERRAL_REWARDS_CONTRACT_ADDRESS,
    liquidityStorageContractAddress: process.env.REACT_APP_LIQUIDITY_STORAGE_CONTRACT_ADDRESS,
    liquiditySourceAddress: process.env.REACT_APP_LIQUIDITY_SOURCE_ADDRESS,
    sentryDns: process.env.REACT_APP_SENTRY_DSN,
    sepoliaRpcUrl: process.env.REACT_APP_SEPOLIA_RPC_URL
};

export default config;
