import React, {useEffect, useState} from "react";
import {addReferralCode, getReferralCode} from "../../api/referral";
import {toast} from "react-toastify";
import config from "../../config/config";

interface Props {
    className?: string;
    address: string;
}

const ReferralInput = ({className, address}: Props) => {
    const [referralLink, setReferralLink] = useState("");
    const [showTooltip, setShowTooltip] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currentCode, setCurrentCode] = useState("");

    useEffect(() => {
        try {
            if (address) {
                getReferralCode(address)
                    .then(value => {
                        setCurrentCode(value);
                        setReferralLink(value);
                    });
            }
        } catch (error) {
            toast(error.message, {type: 'error'});
        }
    }, [address]);

    const onCodeEdit = () => {
        if (referralLink === currentCode) {
            console.log('Code not changed, ignoring');
            return;
        }
        addReferralCode(address, referralLink)
            .then(() => {
                toast("Code changed successfully!", {type: 'success'});
            })
            .catch(cause => {
                let message = cause?.response?.data?.error;
                if (!message) {
                    message = 'Something wrong!';
                }
                toast(message, {type: 'error'});
                setReferralLink(currentCode);
            });
    }

    const onCopied = () => {
        navigator.clipboard.writeText(config.websiteUrl + "/r/" + referralLink);
        setShowTooltip(true);
        setTimeout(() => {
            setShowTooltip(false);
        }, 1000);
    };

    return (
        <div className={`flex flex-col items-center mds:flex-row ${className}`}>
            <div
                className="top-[50%] text-placeholder sm:text-xl left-5 hidden sm:block text-right"
            >
                {config.websiteUrl}/r/{!isEdit ? referralLink : ''}
            </div>
            {isEdit ?
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        setIsEdit(false);
                        onCodeEdit();
                    }}
                    className={`relative w-[260px]`}>

                    <input
                        type="text"
                        id="input-referal"
                        value={referralLink}
                        disabled={!isEdit}
                        onChange={(event) => {
                            setReferralLink(event.target.value);
                        }}
                        className="bg-input border-none text-neutral sm:text-xl rounded-lg block w-full sm:ps-[25px] pe-[5px] p-2.5 outline-none placeholder-neutral"
                        placeholder={currentCode}
                    />
                    <svg
                        className={`absolute top-[50%] translate-y-[-50%] right-[18px] cursor-pointer ${
                            isEdit ? "" : "hidden"
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="20"
                        fill="none"
                        onClick={() => {
                            setIsEdit(false);
                            onCodeEdit();
                        }}
                    >
                        <path
                            fill="#fff"
                            d="M20.47 3.63c.39.39.39 1.01 0 1.4L9.13 16.37a.983.983 0 0 1-1.4 0l-4.2-4.2a.984.984 0 0 1 0-1.4.984.984 0 0 1 1.4 0l3.5 3.5L19.07 3.63a.983.983 0 0 1 1.4 0Zm-2.11-2.12-9.93 9.93-2.79-2.79c-.78-.78-2.05-.78-2.83 0l-1.4 1.4c-.78.78-.78 2.05 0 2.83l5.6 5.6c.78.78 2.05.78 2.83 0L22.59 5.74c.78-.78.78-2.05 0-2.83l-1.4-1.4c-.79-.78-2.05-.78-2.83 0Z"
                        />
                    </svg>
                </form>
                :
                <div className={`relative w-[100px]`}>
                    <svg
                        className={`absolute top-[50%] translate-y-[-50%] right-[58px] cursor-pointer ${
                            isEdit ? "hidden" : ""
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="20"
                        fill="none"
                        onClick={() => setIsEdit(true)}
                    >
                        <path
                            stroke="#fff"
                            strokeWidth="1.5"
                            d="M5 15.293v1.793a.5.5 0 0 0 .5.5h1.793a.5.5 0 0 0 .353-.146l8.5-8.5-2.5-2.5-8.5 8.5a.5.5 0 0 0-.146.353ZM15.59 4.496l2.5 2.5 1.203-1.203a1 1 0 0 0 0-1.414l-1.086-1.086a1 1 0 0 0-1.414 0L15.59 4.496Z"
                        />
                    </svg>
                    <svg
                        className="absolute top-[50%] translate-y-[-50%] right-[20px] cursor-pointer"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        onClick={onCopied}
                    >
                        <path
                            stroke="#fff"
                            strokeWidth="1.5"
                            d="M5.233 11c0-2.828 0-4.243.948-5.121C7.127 5 8.653 5 11.701 5h3.234c3.049 0 4.574 0 5.52.879.948.878.948 2.293.948 5.121v5c0 2.828 0 4.243-.947 5.121-.947.879-2.472.879-5.52.879H11.7c-3.048 0-4.574 0-5.52-.879-.948-.878-.948-2.293-.948-5.121v-5Z"
                        />
                        <path
                            stroke="#fff"
                            strokeWidth="1.5"
                            d="M5.234 19c-.858 0-1.68-.316-2.287-.879A2.894 2.894 0 0 1 2 16v-6c0-3.771 0-5.657 1.263-6.828C4.526 2 6.56 2 10.624 2h4.312c.858 0 1.68.316 2.287.879.606.562.947 1.325.947 2.121"
                        />
                    </svg>
                    <div
                        id="tooltip-bottom"
                        role="tooltip"
                        className={`absolute right-0 mt-2 z-10 inline-block px-3 py-2 text-sm font-medium text-white bg-secondary text-neutral rounded-lg shadow-sm ${
                            showTooltip ? "" : "hidden"
                        }`}
                    >
                        Link copied
                    </div>
                </div>}
        </div>
    );
};

export default ReferralInput;
