import React, {useEffect, useState} from "react";
import ReferralInput from "../../components/ReferralInput";
import ReferralRewardPercentage from "../../components/ReferralRewardPercentage";
import {StakingPool} from "../../model/stakingPool";
import {useAccount} from "wagmi";
import {ReferralStatistics} from "../../model/referral";
import {useClaimReferralReward} from "../../helpers/contracts";
import {formatUsdcLabel} from "../../utils";
import {toast} from "react-toastify";
import {clsx} from "clsx";
import {getStatistics, getTotalRewards} from "../../api/referral";
import {useYieldstoneSession} from "../../helpers/yieldstone";
import * as Sentry from "@sentry/react";

const ReferralTab = () => {
    const {address, connector} = useAccount();
    const [statistics, setStatistics] = useState<null | ReferralStatistics>(null);
    const [isClaiming, setIsClaiming] = useState(false);
    const [totalRewards, setTotalRewards] = useState(0);
    const doClaimReward = useClaimReferralReward();
    const {isLoggedIn} = useYieldstoneSession();

    useEffect(() => {
        if (address && isLoggedIn) {
            getStatistics()
                .then(setStatistics)
                .then(() => {
                    getTotalRewards().then(setTotalRewards);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        console.error(error);
                        toast("Your authentication token has been expired. Please, connect your wallet again.", {type: 'warning'});
                        connector?.disconnect();
                    }
                });
        } else {
            setStatistics(null);
        }
        // eslint-disable-next-line
    }, [address, isLoggedIn]);

    const onClaimClick = () => {
        if (address) {
            setIsClaiming(true);
            doClaimReward(address)
                .then(() => {
                    toast("Rewards claimed!", {type: 'success'});
                    getTotalRewards().then(setTotalRewards);
                })
                .catch(error => {
                    toast("Something went wrong during reward claiming...", {type: 'error'});
                    console.error(error);
                    Sentry.captureException(error);
                })
                .finally(() => {
                    setIsClaiming(false);
                });
        }
    }

    const claimBtnClasses = clsx(
        "block sm:text-xl font-normal py-2 sm:py-3 px-5 sm:px-9 rounded-full mx-auto md:mt-6",
        {
            "navButton bg-accent hover:bg-accent text-secondary mt-3": !isClaiming && BigInt(totalRewards) > BigInt(0),
            "bg-disabled text-inactive": isClaiming || BigInt(totalRewards) === BigInt(0)
        }
    );

    return (
        <>
            <div className="flex flex-col mds:flex-row gap-4 md:gap-8 items-center mb-5">
                <h4 className="text-neutral sm:text-[22px] shrink-0">Referral link</h4>
                <ReferralInput address={address} className="w-full"/>
            </div>
            <table className="sm:text-lg text-neutral font-light mb-8">
                <tbody>
                <tr>
                    <td>Referral gain for 30-day Locked Staking pool:</td>
                    <td className="block ml-4"><ReferralRewardPercentage type={StakingPool.LOCKED_30_DAYS}/></td>
                </tr>
                <tr>
                    <td>Referral gain for 90-day Locked Staking pool:</td>
                    <td className="block ml-4"><ReferralRewardPercentage type={StakingPool.LOCKED_90_DAYS}/></td>
                </tr>
                </tbody>
            </table>
            <table className="sm:text-lg text-neutral font-light w-full">
                <tbody>
                <tr className="mb-5">
                    <th className="pb-5 text-lg sm:text-2xl text-neutral text-left font-normal">
                        Statistics
                    </th>
                    <th className="pb-5 text-lg sm:text-2xl text-neutral text-left font-normal">
                        Rewards
                    </th>
                </tr>
                <tr>
                    <td>Total referral wallets: {statistics?.walletsCount ?? '-'}</td>
                    <td>Available: {formatUsdcLabel(BigInt(totalRewards), 7)}</td>
                </tr>
                <tr>
                    <td>Total referral staked: {statistics ? formatUsdcLabel(statistics.stakedAmount) : '-'}</td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <button
                            className={claimBtnClasses}
                            onClick={onClaimClick}
                            disabled={isClaiming || BigInt(totalRewards) === BigInt(0)}
                        >
                            {isClaiming ? 'Claiming...' : 'Claim'}
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </>
    );
};

export default ReferralTab;
