import React, {useEffect, useState} from "react";
import {useUsdcBalance} from "../../../helpers/wagmi";
import config from '../../../config/config';
import {formatUsdcLabel} from "../../../utils";
import {getTotalRewardsForAllAddresses} from "../../../api/referral";
import {useAccount} from "wagmi";
import {
    useAddLiquidityForReferralRewards,
    useContractOwnerByAddress,
    useRemoveLiquidityForReferralRewards
} from "../../../helpers/contracts";
import {toast} from "react-toastify";
import VariableInput from "../VariableInput";

const ReferralProgramInfoTab = () => {
    const { data: balance, refetch: refetchBalance } = useUsdcBalance(config.referralRewardsContractAddress);

    const { address, connector } = useAccount();
    const { data: owner } = useContractOwnerByAddress(config.referralRewardsContractAddress);
    const [isOwner, setIsOwner] = useState<boolean>(false);

    const [liquidityLabel, setLiquidityLabel] = useState("");
    const [totalRewardsLabel, setTotalRewardsLabel] = useState("");
    const [liquidityReserveLabel, setLiquidityReserveLabel] = useState("");

    const [liquidity, setLiquidity] = useState("");
    const [isAddingLiquidity, setIsAddingLiquidity] = useState(false);
    const [isRemovingLiquidity, setIsRemovingLiquidity] = useState(false);
    const doAddLiquidity = useAddLiquidityForReferralRewards();
    const doRemoveLiquidity = useRemoveLiquidityForReferralRewards();

    useEffect(() => {
        if (balance !== undefined && balance !== null) {
            setLiquidityLabel(formatUsdcLabel(BigInt(Number(balance)), 6));
            getTotalRewardsForAllAddresses()
                .then((totalRewards) => {
                    setTotalRewardsLabel(formatUsdcLabel(BigInt(Number(totalRewards)), 6));
                    const liquidityReserve = BigInt(Number(balance)) - BigInt(Number(totalRewards));
                    setLiquidityReserveLabel(formatUsdcLabel(liquidityReserve, 6));
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        console.error(error);
                        toast("Your authentication token has been expired. Please, connect your wallet again.", {type: 'warning'});
                        connector?.disconnect();
                    }
                });
        }
        // eslint-disable-next-line
    }, [balance]);

    useEffect(() => {
        setIsOwner(address === owner);
    }, [address, owner]);

    const onAddClick = () => {
        if (!liquidity || isNaN(Number(liquidity))) {
            toast('Wrong number format.', {type: 'error'});
            return;
        }
        setIsAddingLiquidity(true);

        doAddLiquidity(Number(liquidity))
            .then(() => {
                toast("Liquidity was added successfully!", {type: 'success'});
                refetchBalance();
            })
            .catch((error) => {
                toast(error.shortMessage, {type: 'error'});
            })
            .finally(() => {
                setIsAddingLiquidity(false);
            });
    }

    const onRemoveClick = () => {
        if (!liquidity || isNaN(Number(liquidity))) {
            toast('Wrong number format.', {type: 'error'});
            return;
        }
        setIsRemovingLiquidity(true);

        doRemoveLiquidity(address, Number(liquidity))
            .then(() => {
                toast("Liquidity was removed successfully!", {type: 'success'});
                refetchBalance();
            })
            .catch((error) => {
                toast(error.shortMessage, {type: 'error'});
            })
            .finally(() => {
                setIsRemovingLiquidity(false);
            });
    }

    return (
        <div className="plain-card bg-card p-4 rounded-[15px] w-full">
            <div className="flex flex-col md:flex-row gap-4 md:gap-8">
                <h3 className="plain-card__name sm:text-[22px] text-neutral font-medium">Referral program</h3>
            </div>
            <div className="bill-info flex justify-center my-[30px]">
                <div className="bill-info__item flex flex-col sm:text-xl text-center text-neutral font-light">
                    <span className="bill-info__name">Liquidity</span>
                    <span className="bill-info__value">{liquidityLabel}</span>
                </div>
                <div className="dellimiter-vertical bg-[#D9D9D9] h-[52px] w-[5px] rounded-full mx-4"></div>
                <div className="bill-info__item flex flex-col sm:text-xl text-center text-neutral font-light">
                    <span className="bill-info__name">Total rewards</span>
                    <span className="bill-info__value">{totalRewardsLabel}</span>
                </div>
                <div className="dellimiter-vertical bg-[#D9D9D9] h-[52px] w-[5px] rounded-full mx-4"></div>
                <div className="bill-info__item flex flex-col sm:text-xl text-center text-neutral font-light">
                    <span className="bill-info__name">Liquidity reserve</span>
                    <span className="bill-info__value">{liquidityReserveLabel}</span>
                </div>
            </div>
            <div className="flex flex-col md:flex-row gap-2 md:gap-4 mt-5">
                <div className="md:w-4/12 flex justify-between">
                    <label className="block text-neutral sm:text-lg font-normal bold mt-2" htmlFor="liquidity">
                        Liquidity
                    </label>
                    <span className="inline-block pt-2 text-neutral">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" strokeWidth={1}
                             stroke="currentColor" className="size-5">
                            <path
                                d="M3 3.5A1.5 1.5 0 0 1 4.5 2h6.879a1.5 1.5 0 0 1 1.06.44l4.122 4.12A1.5 1.5 0 0 1 17 7.622V16.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 3 16.5v-13Z"/>
                        </svg>
                    </span>
                </div>
                <div className="md:w-4/12 relative">
                    <VariableInput value={liquidity} onChange={setLiquidity} id="liquidity" unit="USDC" isDisabled={!isOwner} />
                </div>
                <div className="md:w-4/12 flex justify-start">
                    {isOwner &&
                        <>
                            <button
                                className={`inline-block sm:text-base font-normal py-2 px-2 rounded-full mx-1 w-[105px] ${liquidity !== "" && !(isAddingLiquidity || isRemovingLiquidity)
                                    ? "bg-accent text-secondary"
                                    : "bg-disabled text-inactive pointer-events-none"}`}
                                onClick={onAddClick}
                            >
                                {isAddingLiquidity ? "Adding..." : "Add"}
                            </button>
                            <button
                                className={`inline-block sm:text-base font-normal py-2 px-2 rounded-full mx-1 w-[105px] ${liquidity !== "" && !(isAddingLiquidity || isRemovingLiquidity)
                                    ? "bg-neutral text-secondary"
                                    : "bg-disabled text-inactive pointer-events-none"}`}
                                onClick={onRemoveClick}
                            >
                                {isRemovingLiquidity ? "Removing..." : "Remove"}
                            </button>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default ReferralProgramInfoTab;