import React, {useState} from "react";
import {toast} from "react-toastify";
import {StakingPool} from "../../../model/stakingPool";

interface Props {
    poolType: StakingPool;
    value: string;
    onAction: (type: StakingPool, amount: number) =>  Promise<any>;
    onSuccessMessage: string;
    onSuccess?: () =>  void;
    title: string;
    activeTitle: string;
    colorClassName?: string;
}

const VariableActionButton = ({ poolType, value, onAction, onSuccessMessage, onSuccess, title, activeTitle, colorClassName }: Props) => {
    const [isActive, setIsActive] = useState(false);

    const onActionClick = () => {
        if (!value || isNaN(Number(value))) {
            toast('Wrong number format.', {type: 'error'});
            return;
        }
        setIsActive(true);

        onAction(poolType, Number(value))
            .then(() => {
                toast(onSuccessMessage, {type: 'success'});
                onSuccess?.();
            })
            .catch((error) => {
                toast(error.shortMessage, {type: 'error'});
            })
            .finally(() => {
                setIsActive(false);
            });
    }

    const buttonClassName = `${value !== "" && !isActive
        ? `${colorClassName ?? "bg-accent"} text-secondary`
        : "bg-disabled text-inactive pointer-events-none"}`

    return (
        <button
            className={`inline-block sm:text-base font-normal py-2 px-2 rounded-full mx-1 w-[105px] ${buttonClassName}`}
            onClick={onActionClick}
        >
            {isActive ? activeTitle : title}
        </button>
    );
}

export default VariableActionButton;