import { useAccount } from "wagmi";
import { shortenAddress } from "../../utils";
import ActionButton from "../ActionButton";

export const ConnectWalletButton = ({ onClick }) => {
  const { address, isConnected } = useAccount();

  return (
    <ActionButton onClick={onClick}>
      {isConnected ? shortenAddress(address) : "Connect Wallet"}
    </ActionButton>
  );
};
