import React, {useState} from "react";
import {StakingPool} from "../../model/stakingPool";
import StakingPoolInfoCard from "../../components/AdminPage/StakingPoolInfoCard";
import ReferralProgramInfoTab from "../../components/AdminPage/ReferralProgramInfoTab";
import LiquidityStorageTab from "../../components/AdminPage/LiquidityStorageTab";

const AdminTab = () => {
    const [activeTab, setActiveTab] = useState(0);
    const tabs = [
        {
            title: "Liquidity Staking pool",
            card: <StakingPoolInfoCard poolType={StakingPool.LIQUIDITY} title="Liquidity Staking pool" />
        },
        {
            title: "30-day Locked Staking pool",
            card: <StakingPoolInfoCard poolType={StakingPool.LOCKED_30_DAYS} title="30-day Locked Staking pool" />
        },
        {
            title: "90-day Locked Staking pool",
            card: <StakingPoolInfoCard poolType={StakingPool.LOCKED_90_DAYS} title="90-day Locked Staking pool" />
        },
        {
            title: "Referral program",
            card: <ReferralProgramInfoTab />
        },
        {
            title: "Liquidity storage",
            card: <LiquidityStorageTab />
        }
    ]

    return (
        <>
            <div className="tab-nav flex gap-4 justify-center mb-[33px] items-center md:flex-nowrap flex-wrap">
                {tabs.map((tab, tabIndex) => (
                    <button
                        key={"tab-" + tabIndex}
                        data-index={tabIndex}
                        className={`text-base text-neutral font-normal py-[10px] px-6 rounded-xl cursor-pointer tracking-[0.03em] ${
                            tabIndex === activeTab ? "bg-primary" : "bg-surface"
                        }`}
                        onClick={() => setActiveTab(tabIndex)}
                    >
                        {tab.title}
                    </button>
                ))}
            </div>
            <div className="plains flex flex-row flex-wrap gap-5 justify-center">
                {tabs[activeTab].card}
            </div>
        </>
    );
};

export default AdminTab;