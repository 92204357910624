import {StakingPool} from "../../../model/stakingPool";
import React, {useState} from "react";
import StakingPoolInfoManagement from "../StakingPoolInfoManagement";
import StakingPoolInfoHistory from "../StakingPoolInfoHistory";

interface Props {
    poolType: StakingPool;
    title: string;
}

const StakingPoolInfoCard = ({ poolType, title }: Props) => {
    const [activeTab, setActiveTab] = useState(0);
    const tabs = [
        { title: "Management", content: <StakingPoolInfoManagement poolType={poolType} /> },
        { title: "History", content: <StakingPoolInfoHistory poolType={poolType} /> }
    ];

    return (
        <div className="plain-card bg-card p-4 rounded-[15px] w-full">
            <div className="flex flex-col justify-between md:flex-row gap-4 md:gap-8">
                <h3 className="plain-card__name sm:text-[22px] text-neutral font-medium">{title}</h3>
                <div className="tab-nav flex gap-4 justify-center items-center md:flex-nowrap flex-wrap">
                    {tabs.map((tabItem, indexTab) => (
                        <button
                            key={"tab-" + indexTab}
                            data-index={indexTab}
                            className={`text-base text-neutral font-normal py-[10px] px-6 rounded-xl cursor-pointer tracking-[0.03em] ${
                                indexTab === activeTab ? "bg-primary" : "bg-surface"
                            }`}
                            onClick={() => setActiveTab(indexTab)}
                        >
                            {tabItem.title}
                        </button>
                    ))}
                </div>
            </div>
            {tabs[activeTab].content}
        </div>
    );
}

export default StakingPoolInfoCard;