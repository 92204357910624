import React, {useEffect} from "react";
import {useAllowance} from "../../helpers/contracts";

interface Props {
    address: string;
    onReady: (value: bigint) => void;
}

export const AllowanceCallbackComponent = ({address, onReady}: Props) => {
    const {data, isError, isLoading} = useAllowance(address);

    useEffect(() => {
        if (isError || isLoading) {
            onReady(0n);
        }
        if (!isLoading && typeof data !== "undefined") {
            onReady(data);
        }
    }, [data, isError, isLoading, onReady]);

    return <></>;
}

export default AllowanceCallbackComponent;