import {StakingPool} from "../model/stakingPool";
import {VariablesUpdatesResponseDto} from "./dto/VariablesUpdatesResponseDto";
import {client, url} from "./client";
import {getContractAddressByType} from "../helpers/contracts";

export const getVariablesUpdatesHistory = async(poolType: StakingPool, variableType: string, currentPage: number, changedAtOrder: string): Promise<VariablesUpdatesResponseDto> => {
    const response = (await client.get(
        url('/api/variable-update'),
        {
            params: {
                contractAddress: getContractAddressByType(poolType),
                ...(variableType && { variableType: variableType }),
                currentPage: currentPage,
                changedAtOrder: changedAtOrder.toLowerCase()
            }
        }
    ));
    return response.data;
}