import { format } from "date-fns";
import { LockedStaking } from "../model/staking";

export const numberFormat = new Intl.NumberFormat('en-US');

export const shortenAddress = (address: string, displayCount: number = 4) =>
    address.replace(address.slice(displayCount, -displayCount), '. . .');

export const formatUsdcLabel = (value: bigint, fractionDigits = 3) => `${fromUsdcNumber(Number(value)).toFixed(fractionDigits)} USDC`;

export const fromUsdcNumber = (value: number) => value / 10 ** 6;

export const toUsdcNumber = (value: number) => value * 10 ** 6;

export const toUsdcBigInt = (value: number) => BigInt(toUsdcNumber(value));

export const formatDate = (value: bigint) => {
    const date = new Date(Number(value) * 1000);
    return format(date, "dd-LLL-yyyy");
}

export const isStakingPeriodEnded = (stake: LockedStaking) => {
    return Number(stake.expirationOn) * 1000 <= Date.now();
}
