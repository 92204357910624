import {useAccount} from "wagmi";
import {useEffect, useState} from "react";
import {checkIsAdmin} from "../api/auth";
import config from "../config/config";
import {StakingPool} from "../model/stakingPool";
import {getVariablesUpdatesHistory} from "../api/variable";

export const useAdmin = () => {
    const { address, isConnected } = useAccount();
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const updateIsAdmin = async () => {
            const isAdmin = isConnected ? (await checkIsAdmin(address)).is_admin : false;
            setIsAdmin(isAdmin);
        };

        updateIsAdmin();
    }, [isConnected, address]);

    return isAdmin;
}

export const useLiquiditySource = () => {
    const { address, isConnected } = useAccount();
    const [isLiquiditySource, setIsLiquiditySource] = useState(false);

    useEffect(() => {
        setIsLiquiditySource(address === config.liquiditySourceAddress);
    }, [isConnected, address]);

    return isLiquiditySource;
}

export const useVariablesUpdates = (poolType: StakingPool, variableType: string, currentPage: number, changedAtOrder: string) => {
    const [updates, setUpdates] = useState([]);
    const [total, setTotal] = useState(0);
    const [lastPage, setLastPage] = useState(1);
    const [pageStart, setPageStart] = useState(0);
    const [pageEnd, setPageEnd] = useState(0);

    useEffect(() => {
        getVariablesUpdatesHistory(poolType, variableType, currentPage, changedAtOrder)
            .then((updates) => {
                setUpdates(updates.data);
                setTotal(updates.meta.total);
                setLastPage(updates.meta.lastPage);
                setPageStart(updates.meta.pageStart);
                setPageEnd(updates.meta.pageEnd);
            });
    }, [poolType, variableType, currentPage, changedAtOrder]);

    return { updates, total, lastPage, pageStart, pageEnd };
}