import React, {useEffect, useState} from "react";
import {format} from "date-fns";
import {fromUsdcNumber, shortenAddress} from "../../../utils";
import {StakingPool} from "../../../model/stakingPool";
import {toast} from "react-toastify";
import {useVariablesUpdates} from "../../../helpers/admin";

interface Props {
    poolType: StakingPool;
}

interface VariableType {
    type: string,
    name: string,
    unit: string,
    formatValue: (value: string) => string;
}

const StakingPoolInfoHistory = ({ poolType }: Props) => {
    const variableTypes: VariableType[] = [
        {
            type: "",
            name: "All variables",
            unit: "",
            formatValue: value => value
        },
        {
            type: "APR",
            name: "APY",
            unit: "%",
            formatValue: value => String(Number(value) / 100)
        },
        {
            type: "MAX_POOL_AMOUNT",
            name: "Max pool amount",
            unit: "USDC",
            formatValue: value => String(fromUsdcNumber(Number(value)))
        },
        {
            type: "MIN_STAKING_AMOUNT",
            name: "Min staking amount",
            unit: "USDC",
            formatValue: value => String(fromUsdcNumber(Number(value)))
        },
        {
            type: "MAX_STAKING_AMOUNT",
            name: "Max staking amount",
            unit: "USDC",
            formatValue: value => String(fromUsdcNumber(Number(value)))
        },
        {
            type: "REFERRAL_REWARD",
            name: "Referral reward",
            unit: "%",
            formatValue: value => String(Number(value) * 100)
        }
    ]

    const [variableTypeFilter, setVariableTypeFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [changedAtOrder, setChangedAtOrder] = useState('asc');

    const { updates, total, lastPage, pageStart, pageEnd } = useVariablesUpdates(poolType, variableTypeFilter, currentPage, changedAtOrder);

    useEffect(() => {
        setCurrentPage(1);
    }, [variableTypeFilter, changedAtOrder]);

    const onAddressCopy = (address: string) => {
        navigator.clipboard.writeText(address);
        toast("Initiator address copied!", { type: 'success' });
    };

    return (
        <>
            <div className="relative overflow-x-auto sm:rounded-lg mt-4">
                <div className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4 text-neutral">
                    <select className="inline-flex items-center font-medium rounded-lg text-sm px-3 py-1.5 bg-surface focus-visible:outline-none"
                            value={variableTypeFilter}
                            onChange={e => setVariableTypeFilter(e.target.value)}
                    >
                        {variableTypes.map((variableType) => (
                            <option
                                key={`variableType-${variableType.type}`}
                                value={variableType.type}
                            >
                                {variableType.name}
                            </option>
                        ))}
                    </select>
                </div>

                <table className="w-full text-sm text-left text-neutral bg-input sm:rounded-lg">
                    <thead className="text-xs uppercase">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-center">
                                Parameter
                            </th>
                            <th scope="col" className="px-6 py-3 text-center">
                                Value
                            </th>
                            <th scope="col" className="px-6 py-3">
                                <div className="flex items-center justify-center">
                                    Changed
                                    <button onClick={() => setChangedAtOrder(changedAtOrder === 'asc' ? 'desc' : 'asc')}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="1.5" stroke="currentColor" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"/>
                                        </svg>
                                    </button>
                                </div>
                            </th>
                            <th scope="col" className="px-6 py-3 text-center">
                                Initiator address
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {updates.map((item, index) => {
                        const variableType = variableTypes.find((type) => type.type === item.variable);

                        return (
                                <tr key={`history-item-${index}`} className="border-t border-t-card hover:bg-primary">
                                    <th scope="row"
                                        className="px-4 py-4 font-medium whitespace-nowrap">
                                        {variableType?.name}
                                    </th>
                                    <td className="px-4 py-4">
                                        {`${variableType.formatValue(item.value)} ${variableType?.unit}`}
                                    </td>
                                    <td className="px-4 py-4 text-nowrap">
                                        {format(item.changedAt, "dd-LLL-yyyy")}
                                    </td>
                                    <td className="px-4 py-4 flex justify-between" title={item.initiatorAddress}>
                                        <span className="inline-block">
                                            {shortenAddress(item.initiatorAddress, 8)}
                                        </span>
                                        <svg
                                            className="inline-block cursor-pointer"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            onClick={() => onAddressCopy(item.initiatorAddress)}
                                        >
                                            <path
                                                stroke="#fff"
                                                strokeWidth="1.5"
                                                d="M5.233 11c0-2.828 0-4.243.948-5.121C7.127 5 8.653 5 11.701 5h3.234c3.049 0 4.574 0 5.52.879.948.878.948 2.293.948 5.121v5c0 2.828 0 4.243-.947 5.121-.947.879-2.472.879-5.52.879H11.7c-3.048 0-4.574 0-5.52-.879-.948-.878-.948-2.293-.948-5.121v-5Z"
                                            />
                                            <path
                                                stroke="#fff"
                                                strokeWidth="1.5"
                                                d="M5.234 19c-.858 0-1.68-.316-2.287-.879A2.894 2.894 0 0 1 2 16v-6c0-3.771 0-5.657 1.263-6.828C4.526 2 6.56 2 10.624 2h4.312c.858 0 1.68.316 2.287.879.606.562.947 1.325.947 2.121"
                                            />
                                        </svg>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {updates.length > 0 &&
                    <nav className="flex items-center flex-column flex-wrap md:flex-row justify-between py-4"
                         aria-label="Table navigation">
                        <span className="text-sm font-normal text-neutral mb-4 md:mb-0 block w-full md:inline md:w-auto">
                            Showing <span className="font-semibold">{pageStart}-{pageEnd}</span>&nbsp;
                            of <span className="font-semibold">{total}</span>
                        </span>
                        {lastPage > 1 &&
                            <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm text-neutral h-8">
                                <li>
                                    <button
                                        className="flex items-center justify-center px-3 h-8 ms-0 leading-tight border-r border-r-card bg-input enabled:hover:bg-primary disabled:bg-disabled rounded-s-lg"
                                        onClick={() => setCurrentPage(1)}
                                        disabled={currentPage === 1}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                             className="size-5">
                                            <path fillRule="evenodd"
                                                  d="M4.72 9.47a.75.75 0 0 0 0 1.06l4.25 4.25a.75.75 0 1 0 1.06-1.06L6.31 10l3.72-3.72a.75.75 0 1 0-1.06-1.06L4.72 9.47Zm9.25-4.25L9.72 9.47a.75.75 0 0 0 0 1.06l4.25 4.25a.75.75 0 1 0 1.06-1.06L11.31 10l3.72-3.72a.75.75 0 0 0-1.06-1.06Z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="flex items-center justify-center px-3 h-8 leading-tight border-x border-x-card bg-input enabled:hover:bg-primary disabled:bg-disabled"
                                        onClick={() => setCurrentPage(currentPage - 1)}
                                        disabled={currentPage === 1}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                             className="size-5">
                                            <path fillRule="evenodd"
                                                  d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <button aria-current="page"
                                            className="flex items-center justify-center px-3 h-8 border-x border-x-card bg-surface"
                                            disabled={true}>
                                        {currentPage}
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="flex items-center justify-center px-3 h-8 leading-tight border-x border-x-card bg-input enabled:hover:bg-primary disabled:bg-disabled"
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                        disabled={currentPage === lastPage}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                             className="size-5">
                                            <path fillRule="evenodd"
                                                  d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="flex items-center justify-center px-3 h-8 leading-tight border-l border-l-card bg-input enabled:hover:bg-primary disabled:bg-disabled rounded-e-lg"
                                        onClick={() => setCurrentPage(lastPage)}
                                        disabled={currentPage === lastPage}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                             className="size-5">
                                            <path fillRule="evenodd"
                                                  d="M15.28 9.47a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 1 1-1.06-1.06L13.69 10 9.97 6.28a.75.75 0 0 1 1.06-1.06l4.25 4.25ZM6.03 5.22l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L8.69 10 4.97 6.28a.75.75 0 0 1 1.06-1.06Z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        }
                    </nav>
                }
            </div>
        </>
    );
}

export default StakingPoolInfoHistory;