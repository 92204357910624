import React from "react";

interface Props {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
}

export const ActionButton = ({ children, className, onClick }: Props) => {
  return (
    <button
      onClick={onClick}
      className={`transition ease-linear bg-accent hover:bg-bg-hover shadow-primary hover:bg-hover hover:shadow-active text-secondary hover:text-neutral font-normal py-[10px] px-6 xs:px-[35px] rounded-full justify-self-end flex gap ${className}`}
    >
      {children}
    </button>
  );
};

export default ActionButton;
