import {client, url} from './client';
import {NonceResponseDto} from "./dto/NonceResponseDto";
import {LoginResponseDto} from "./dto/LoginResponseDto";
import {LoginRequestDto} from "./dto/LoginRequestDto";
import {IsAdminResponseDto} from "./dto/IsAdminResponseDto";

export const getNonce = async (address: string) : Promise<NonceResponseDto> => {
    const response = await client.get<NonceResponseDto>(url(`/api/auth/nonce/${address}`));
    return response.data;
}

export const login = async(params: LoginRequestDto) : Promise<LoginResponseDto> => {
    const response = await client.post<LoginResponseDto>(url(`/api/auth/login`), params);
    return response.data;
}

export const checkIsAdmin = async(address: string) : Promise<IsAdminResponseDto> => {
    const response = await client.get<IsAdminResponseDto>(url(`/api/auth/is_admin/${address}`));
    return response.data;
}
