import VariableField from "../VariableField";
import VariableActionButton from "../VariableActionButton";
import React from "react";
import {StakingPool} from "../../../model/stakingPool";

interface Props {
    poolType: StakingPool;
    value: string;
    onChange: (newValue: string) => void;
    id: string;
    label: string;
    unit: string;
    onSave: (type: StakingPool, amount: number) =>  Promise<any>;
    isStoredInContract?: boolean;
}

const SavingVariableField = ({ poolType, value, onChange, id, label, unit, onSave, isStoredInContract }: Props) => {
    return (
        <VariableField
            poolType={poolType}
            value={value}
            onChange={onChange}
            id={id}
            label={label}
            unit={unit}
            isStoredInContract={isStoredInContract}
        >
            <VariableActionButton
                poolType={poolType}
                value={value}
                onAction={onSave}
                onSuccessMessage="Saved successfully!"
                title="Save"
                activeTitle="Saving..."
            />
        </VariableField>
    );
}

export default SavingVariableField;