import React, {useEffect, useState} from "react";
import UsdcBalanceValue from "../../components/UsdcBalanceValue";
import StakedValue from "../../components/StakedValue";
import {StakingPool} from "../../model/stakingPool";
import StakingPoolAPYValue from "../../components/StakingPoolAPYValue";
import {useStakeUsdc} from "../../helpers/contracts";
import {useAccount} from "wagmi";
import AmountInput from "../../components/AmountInput";
import {toUsdcNumber} from "../../utils";
import {toast} from "react-toastify";
import AllowanceCallbackComponent from "../../components/AllowanceCallbackComponent";
import {useStats} from "../../context/StatsContext";

const StakeTab = () => {
    const doStake = useStakeUsdc();

    const {address, isConnected} = useAccount();
    const [selectedStake, setSelectedStake] = useState(-1);
    const [amountToStake, setAmountToStake] = useState("");
    const [isStaking, setIsStaking] = useState(false);
    const [availableAmount, setAvailableAmount] = useState(0n);
    const [allowance, setAllowance] = useState(0n);

    const { setShouldUpdateStats } = useStats();

    useEffect(() => {
        if (!isConnected) {
            setAmountToStake("");
        }
    }, [isConnected]);


    const onStakeClick = () => {
        if (!amountToStake || selectedStake < 0 || isNaN(Number(amountToStake))) {
            if (isNaN(Number(amountToStake))) {
                toast('Wrong number format.', {type: 'error'});
            }
            return;
        }
        setIsStaking(true);

        doStake(
            StakingPool[StakingPool[selectedStake]],
            BigInt(toUsdcNumber(Number(amountToStake))),
            allowance
        )
            .then(() => {
                toast("Staked successfully!", {type: 'success'});
                setAmountToStake("");
                setSelectedStake(-1);
                setShouldUpdateStats(true);
            })
            .catch((error) => {
                toast(error.shortMessage, {type: 'error'});
            })
            .finally(() => {
                setIsStaking(false);
            });
    };

    const regularStakeClasses =
        "plain-card bg-card py-[10px] px-[14px] rounded-[15px] w-full md:max-w-[228px] cursor-pointer select-none";
    const selectedStakeClasses = regularStakeClasses + " bg-primary";
    const stakeButtonInactiveClasses =
        "block bg-disabled text-inactive sm:text-xl font-normal py-2 sm:py-3 px-5 sm:px-9 rounded-full ml-auto mt-10 pointer-events-none";
    const stakeButtonActiveClasses =
        "block bg-accent text-secondary sm:text-xl font-normal py-2 sm:py-3 px-5 sm:px-9 rounded-full ml-auto mt-10";

    return (
        <>
            {address && selectedStake >= 0 &&
                <AllowanceCallbackComponent
                    onReady={(data) => setAllowance(data)}
                    address={address}
                />
            }
            <div className="bill-info flex justify-center mb-[50px]">
                <div className="bill-info__item flex flex-col sm:text-xl text-center text-neutral font-light">
                    <span className="bill-info__name">Balance</span>
                    <span className="bill-info__value">
                        {isConnected ? <UsdcBalanceValue address={address} onUpdate={setAvailableAmount}/> : "-"}
                    </span>
                </div>
                <div className="dellimiter-vertical bg-[#D9D9D9] h-[52px] w-[5px] rounded-full mx-4 md:mx-20"></div>
                <div className="bill-info__item flex flex-col sm:text-xl text-center text-neutral font-light">
                    <span className="bill-info__name">Staked</span>
                    <span className="bill-info__value">
                        {isConnected ? (
                            <StakedValue
                                address={address}
                                pools={[
                                    StakingPool.LIQUIDITY,
                                    StakingPool.LOCKED_30_DAYS,
                                    StakingPool.LOCKED_90_DAYS,
                                ]}
                            />
                        ) : (
                            "-"
                        )}
                    </span>
                </div>
            </div>
            <div className="plains flex flex-wrap md:flex-nowrap gap-5 justify-center">
                <div
                    className={
                        selectedStake === StakingPool.LIQUIDITY
                            ? selectedStakeClasses
                            : regularStakeClasses
                    }
                    onClick={() => setSelectedStake(StakingPool.LIQUIDITY)}
                >
                    <h3 className="plain-card__title sm:text-[22px] text-neutral font-medium">
                        Liquidity Staking pool
                    </h3>
                    <p className="plain-card__percents text-neutral sm:text-xl font-medium">
                        APY <StakingPoolAPYValue type={StakingPool.LIQUIDITY}/>
                    </p>
                    <p className="plain-card__locaking text-base text-neutral font-light mt-4 ml-[3px]">
                        No locking
                    </p>
                </div>
                <div
                    className={
                        selectedStake === StakingPool.LOCKED_30_DAYS
                            ? selectedStakeClasses
                            : regularStakeClasses
                    }
                    onClick={() => setSelectedStake(StakingPool.LOCKED_30_DAYS)}
                >
                    <h3 className="plain-card__title sm:text-[22px] text-neutral font-medium">
                        30-day Locked Staking pool
                    </h3>
                    <p className="plain-card__percents text-neutral sm:text-xl font-medium">
                        APY <StakingPoolAPYValue type={StakingPool.LOCKED_30_DAYS}/>
                    </p>
                    <p className="plain-card__locaking text-base text-neutral font-light mt-4 ml-[3px]">
                        Locking for 30 days
                    </p>
                </div>
                <div
                    className={
                        selectedStake === StakingPool.LOCKED_90_DAYS
                            ? selectedStakeClasses
                            : regularStakeClasses
                    }
                    onClick={() => setSelectedStake(StakingPool.LOCKED_90_DAYS)}
                >
                    <h3 className="plain-card__title sm:text-[22px] text-neutral font-medium">
                        90-day Locked Staking pool
                    </h3>
                    <p className="plain-card__percents text-neutral sm:text-xl font-medium">
                        APY <StakingPoolAPYValue type={StakingPool.LOCKED_90_DAYS}/>
                    </p>
                    <p className="plain-card__locaking text-base text-neutral font-light mt-4 ml-[3px]">
                        Locking for 90 days
                    </p>
                </div>
            </div>
            <AmountInput
                className="mt-10 w-full md:w-[calc(66%_-_2px)] md:ml-auto"
                max={availableAmount}
                value={amountToStake}
                onChange={setAmountToStake}
                disabled={!isConnected}
            />

            <button
                className={
                    amountToStake !== "" && selectedStake >= 0 && !isStaking
                        ? stakeButtonActiveClasses
                        : stakeButtonInactiveClasses
                }
                onClick={onStakeClick}
            >
                {isStaking ? "Staking..." : "Stake"}
            </button>
            {!isConnected && (
                <div className="message mt-5 text-center md:text-right">
          <span className="text-neutral sm:text-xl font-light">
            Connect your wallet to use staking
          </span>
                </div>
            )}
        </>
    );
};

export default StakeTab;
