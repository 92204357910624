export const getStakesAbi = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "staker",
                "type": "address"
            }
        ],
        "name": "getStakes",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "id",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountToClaim",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "apr",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "interest",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "interestToClaim",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "created",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "lastClaimed",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bool",
                        "name": "isCompleted",
                        "type": "bool"
                    }
                ],
                "internalType": "struct YieldstoneLiquidityStakingPool.Staking[]",
                "name": "",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }];

export const getStakesLockedAbi = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "staker",
                "type": "address"
            }
        ],
        "name": "getStakes",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "id",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountToClaim",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "apr",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "interest",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "interestToClaim",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "created",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "expirationOn",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "lastClaimed",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bool",
                        "name": "isCompleted",
                        "type": "bool"
                    }
                ],
                "internalType": "struct Yieldstone30DaysLockedStakingPool.Staking[]",
                "name": "",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];

export const getAPRAbi = [
    {
        "inputs": [],
        "name": "getAPR",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "OwnableUnauthorizedAccount",
        "type": "error"
    },
];

export const setAPRAbi = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_apr",
                "type": "uint256"
            }
        ],
        "name": "setAPR",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
]

export const getMaxPoolAmountAbi = [
    {
        "inputs": [],
        "name": "getMaxPoolAmount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]

export const setMaxPoolAmountAbi= [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_maxPoolAmount",
                "type": "uint256"
            }
        ],
        "name": "setMaxPoolAmount",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
]

export const getMinStakingAmountAbi = [
    {
        "inputs": [],
        "name": "getMinStakingAmount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]

export const setMinStakingAmountAbi = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_minStakingAmount",
                "type": "uint256"
            }
        ],
        "name": "setMinStakingAmount",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
]

export const getMaxStakingAmountAbi = [
    {
        "inputs": [],
        "name": "getMaxStakingAmount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]

export const setMaxStakingAmountAbi = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_maxStakingAmount",
                "type": "uint256"
            }
        ],
        "name": "setMaxStakingAmount",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
]

export const getLiquidityAbi = [
    {
        "inputs": [],
        "name": "getLiquidity",
        "outputs": [
            {
                "internalType": "int256",
                "name": "",
                "type": "int256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]

export const addLiquidityAbi = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "addLiquidity",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
]

export const removeLiquidityAbi = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "removeLiquidity",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
]

export const getTotalStakedAbi = [
    {
        "inputs": [],
        "name": "getTotalStaked",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]

export const getTotalInterestAbi = [
    {
        "inputs": [],
        "name": "getTotalInterest",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]

export const getContractOwnerAbi = [
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]

export const stakeAbi = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "stake",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

export const claimInterestAbi = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "stakingId",
                "type": "uint256"
            }
        ],
        "name": "claimInterest",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
]

export const claimPrincipalAbi = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "stakingId",
                "type": "uint256"
            }
        ],
        "name": "claimPrincipal",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
]

export const claimAllAbi = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "stakingId",
                "type": "uint256"
            }
        ],
        "name": "claimAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

export const claimAmountAbi = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "stakingId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "claimAmount",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

export const restakeAbi = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "stakingId",
                "type": "uint256"
            }
        ],
        "name": "restake",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

export const referralClaimAbi = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "receiver",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "claimId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "deadline",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "signature",
                "type": "bytes"
            }
        ],
        "name": "claim",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
];

export const removeLiquidityFromReferralAbi = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "token",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "recoverToken",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
]