import {StakingPool} from "../model/stakingPool";
import {getContractAddressByType} from "../helpers/contracts";
import {ReferralClaim, ReferralCode, ReferralRewardRate, ReferralStatistics} from "../model/referral";
import {client, url} from './client';

export const getReferralRewardRate = async (type: StakingPool) => {
    const address = getContractAddressByType(type);
    const response = await client.get<ReferralRewardRate>(url(`/api/referral-reward-rate/${address}`));
    return response.data.rate;
}

export const useUpdateReferralRewardRate = () => {
    return async (type: StakingPool, rate: number) => client.post(
        url('/api/referral-reward-rate/update'),
        {
            contractAddress: getContractAddressByType(type),
            rate: (rate / 100)
        }
    );
}

export const getReferralCode = async (address: string) => {
    const response = await client.get<ReferralCode>(url(`/api/referral-code/${address}`));
    return response.data.code;
}

export const addReferralCode = async (address: string, newCode: string) => {
    const response = await client.post<ReferralCode>(url(`/api/referral-code/${address}`), { code: newCode });
    return response.data.code;
}

export const getStatistics = async () : Promise<ReferralStatistics> => {
    const response = await client.get<ReferralStatistics>(url('/api/referral/statistics'));
    return response.data;
}

export const getTotalRewards = async() : Promise<any> => {
    const response = await client.get<any>(url('/api/referral-reward/total'));
    return response.data.sum;
}

export const getTotalRewardsForAllAddresses = async() : Promise<any> => {
    const response = await client.get<any>(url('/api/referral-reward/total-all'));
    return response.data.sum;
}

export const getClaimData = async() : Promise<ReferralClaim> => {
    const response = await client.get<any>(url('/api/referral-claim'));
    return response.data;
}

const storeReferrerCode = (code: string): void => localStorage.setItem('referral_code', code);
export const getReferrerCode = (): string => localStorage.getItem('referral_code');
export const clearReferrerCode = (): void => localStorage.removeItem('referral_code');

export const processReferrerCodeFromUrl = (): void => {
    const m = window.location.pathname.match(/^\/r\/([\w\d_]+).*/);
    if (m) {
        storeReferrerCode(m[1]);
    }
}

export const addNewReferral = (code: string): Promise<any> => client.post(url('/api/referral/add'), {code});
