import React, {useEffect} from "react";
import {formatUsdcLabel} from "../../utils";
import {useStakes} from "../../helpers/contracts";
import {StakingPool} from "../../model/stakingPool";
import {ContractFunctionExecutionError} from "viem";
import {toast} from "react-toastify";
import {useStats} from "../../context/StatsContext";

interface Props {
    address: string
    onLoading?: string | React.ReactNode
    onError?: string | React.ReactNode
    pools: StakingPool[]
}

const StakedValue = ({ address, onError = '-', onLoading = '-', pools }: Props) => {
    const { isLoading, data, isError, error, refetch } = useStakes(address, pools);
    const { shouldUpdateStats, setShouldUpdateStats } = useStats();

    useEffect(() => {
        if (shouldUpdateStats) {
            refetch();
            setShouldUpdateStats(false);
        }
    }, [shouldUpdateStats, setShouldUpdateStats, refetch]);

    if (isError) {
        console.error(error);
        return onError;
    }

    if (isLoading) {
        return onLoading;
    }

    const executionError = data.find(response => response.error && response.error instanceof ContractFunctionExecutionError)?.error;
    if (executionError) {
        if (executionError.message.includes('The address is not a contract.')) {
            toast('You are probably using a wrong network.', { type: 'error' });
        } else {
            toast(executionError.shortMessage, { type: 'error' });
        }
        return onError;
    }

    const totalStaked = data.flatMap(val => val.result)
        .filter(val => !val.isCompleted)
        .map(val => val.amountToClaim)
        .filter(val => !isNaN(Number(val)))
        .reduce((acc, cur) => acc + cur, 0n);

    return formatUsdcLabel(totalStaked);
}

export default StakedValue;