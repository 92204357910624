import {useReadContract, useReadContracts, useWriteContract} from "wagmi";
import config from '../config/config';
import wagmiConfig from '../config/wagmiConfig';
import {
    addLiquidityAbi,
    claimAllAbi,
    claimAmountAbi,
    claimInterestAbi,
    claimPrincipalAbi,
    getAPRAbi,
    getContractOwnerAbi,
    getLiquidityAbi,
    getMaxPoolAmountAbi,
    getMaxStakingAmountAbi,
    getMinStakingAmountAbi,
    getStakesAbi,
    getStakesLockedAbi,
    getTotalInterestAbi,
    getTotalStakedAbi,
    referralClaimAbi,
    removeLiquidityAbi,
    removeLiquidityFromReferralAbi,
    restakeAbi,
    setAPRAbi,
    setMaxPoolAmountAbi,
    setMaxStakingAmountAbi,
    setMinStakingAmountAbi,
    stakeAbi
} from "./abi";
import {StakingPool} from "../model/stakingPool";
import {erc20Abi} from "viem";
import {waitForTransactionReceipt} from "@wagmi/core";
import {getClaimData} from "../api/referral";
import {toUsdcNumber} from "../utils";

export const useStakes = (address: string, pools: StakingPool[]) => {
    const contracts = pools.map(pool => {
        return {
            address: getContractAddressByType(pool),
            abi: getStakesAbiByPool(pool),
            functionName: 'getStakes',
            args: [address]
        }
    });

    return useReadContracts({
        contracts: contracts
    })
}

export const useStakingPoolAPR = (type: StakingPool) => {
    return useReadContract(
        {
            address: getContractAddressByType(type),
            abi: getAPRAbi,
            functionName: 'getAPR'
        }
    );
}

export const useSaveStakingPoolAPR = () => {
    const { writeContractAsync } = useWriteContract();

    return async (type: StakingPool, apr: number) => {
        const saveAPRTxHash = await writeContractAsync({
            address: getContractAddressByType(type),
            abi: setAPRAbi,
            functionName: 'setAPR',
            args: [
                BigInt(apr * 100)
            ]
        });

        return waitForTransactionReceipt(wagmiConfig, {
            hash: saveAPRTxHash
        });
    }
}

export const useTotalStaked = (type: StakingPool) => {
    return useReadContract({
        address: getContractAddressByType(type),
        abi: getTotalStakedAbi,
        functionName: 'getTotalStaked',
    });
}

export const useLiquidity = () => {
    return useReadContract({
        address: config.liquidityStorageContractAddress,
        abi: getLiquidityAbi,
        functionName: 'getLiquidity',
    });
}

export const useStakingPoolInfo = (type: StakingPool) => {
    const contract = {
        address: getContractAddressByType(type)
    }

    const contracts = [
        {
            ...contract,
            abi: getAPRAbi,
            functionName: 'getAPR'
        },
        {
            ...contract,
            abi: getMaxPoolAmountAbi,
            functionName: 'getMaxPoolAmount'
        },
        {
            ...contract,
            abi: getMinStakingAmountAbi,
            functionName: 'getMinStakingAmount'
        },
        {
            ...contract,
            abi: getMaxStakingAmountAbi,
            functionName: 'getMaxStakingAmount'
        },
        {
            ...contract,
            abi: getTotalInterestAbi,
            functionName: 'getTotalInterest'
        }
    ];

    return useReadContracts({
        contracts: contracts
    });
}

export const useTotalInterests = () => {
    const contract = {
        abi: getTotalInterestAbi,
        functionName: 'getTotalInterest'
    }

    const contracts = [
        {
            ...contract,
            address: config.liquidityPoolContractAddress,
        },
        {
            ...contract,
            address: config.lockedPool30DaysContractAddress,
        },
        {
            ...contract,
            address: config.lockedPool90DaysContractAddress,
        }
    ];

    return useReadContracts({
        contracts: contracts
    });
}

export const useSaveMaxPoolAmount = () => {
    const { writeContractAsync } = useWriteContract();

    return async (type: StakingPool, maxPoolAmount: number) => {
        const saveMaxPoolAmountTxHash = await writeContractAsync({
            address: getContractAddressByType(type),
            abi: setMaxPoolAmountAbi,
            functionName: 'setMaxPoolAmount',
            args: [
                BigInt(toUsdcNumber(maxPoolAmount))
            ]
        });

        return waitForTransactionReceipt(wagmiConfig, {
            hash: saveMaxPoolAmountTxHash
        });
    }
}

export const useSaveMinStakingAmount = () => {
    const { writeContractAsync } = useWriteContract();

    return async (type: StakingPool, minStakingAmount: number) => {
        const saveMinStakingAmountTxHash = await writeContractAsync({
            address: getContractAddressByType(type),
            abi: setMinStakingAmountAbi,
            functionName: 'setMinStakingAmount',
            args: [
                BigInt(toUsdcNumber(minStakingAmount))
            ]
        });

        return waitForTransactionReceipt(wagmiConfig, {
            hash: saveMinStakingAmountTxHash
        });
    }
}

export const useSaveMaxStakingAmount = () => {
    const { writeContractAsync } = useWriteContract();

    return async (type: StakingPool, maxStakingAmount: number) => {
        const saveMaxStakingAmountTxHash = await writeContractAsync({
            address: getContractAddressByType(type),
            abi: setMaxStakingAmountAbi,
            functionName: 'setMaxStakingAmount',
            args: [
                BigInt(toUsdcNumber(maxStakingAmount))
            ]
        });

        return waitForTransactionReceipt(wagmiConfig, {
            hash: saveMaxStakingAmountTxHash
        });
    }
}

export const useAddLiquidity = () => {
    const { writeContractAsync } = useWriteContract();

    return async (amount: bigint) => {
        return await writeContractAsync({
            address: config.liquidityStorageContractAddress,
            abi: addLiquidityAbi,
            functionName: 'addLiquidity',
            args: [amount]
        });
    }
}

export const useRemoveLiquidity = () => {
    const { writeContractAsync } = useWriteContract();

    return async (amount: bigint) => {
        return await writeContractAsync({
            address: config.liquidityStorageContractAddress,
            abi: removeLiquidityAbi,
            functionName: 'removeLiquidity',
            args: [amount]
        });
    }
}

export const useContractOwnerByPoolType = (type: StakingPool) => {
    return useContractOwnerByAddress(getContractAddressByType(type));
}

export const useContractOwnerByAddress = (contractAddress: string) => {
    return useReadContract({
        address: contractAddress,
        abi: getContractOwnerAbi,
        functionName: 'owner'
    });
}

export const useAllowance = (owner: string) => {
    return useReadContract({
        address: config.usdcTokenContractAddress,
        abi: erc20Abi,
        functionName: 'allowance',
        args: [
            owner,
            config.liquidityStorageContractAddress
        ]
    });
}

export const useStakeUsdc = () => {
    const { writeContractAsync } = useWriteContract();

    return async (type: StakingPool, amount: bigint, allowance: bigint = 0n) => {
        if (allowance < amount) {
            const approveTxHash = await writeContractAsync({
                address: config.usdcTokenContractAddress,
                abi: erc20Abi,
                functionName: 'approve',
                args: [
                    config.liquidityStorageContractAddress,
                    amount
                ]
            });

            await waitForTransactionReceipt(wagmiConfig, {
                hash: approveTxHash
            });
        }

        const stakeTxHash = await writeContractAsync({
            address: getContractAddressByType(type),
            abi: stakeAbi,
            functionName: 'stake',
            args: [amount]
        });

        return waitForTransactionReceipt(wagmiConfig, {
            hash: stakeTxHash
        });
    };
}

export const useClaimInterest = (type: StakingPool, stakeId: bigint) => {
    const { writeContractAsync } = useWriteContract();

    return async () => {
        const claimTxHash = await writeContractAsync({
            address: getContractAddressByType(type),
            abi: claimInterestAbi,
            functionName: 'claimInterest',
            args: [
                stakeId
            ]
        });

        return waitForTransactionReceipt(wagmiConfig, {
            hash: claimTxHash
        });
    }
}

export const useClaimPrincipal = (type: StakingPool, stakeId: bigint) => {
    const { writeContractAsync } = useWriteContract();

    return async () => {
        const claimTxHash = await writeContractAsync({
            address: getContractAddressByType(type),
            abi: claimPrincipalAbi,
            functionName: 'claimPrincipal',
            args: [
                stakeId
            ]
        });

        return waitForTransactionReceipt(wagmiConfig, {
            hash: claimTxHash
        });
    }
}

export const useClaimAll = (type: StakingPool, stakeId: bigint) => {
    const { writeContractAsync } = useWriteContract();

    return async () => {
        const claimTxHash = await writeContractAsync({
            address: getContractAddressByType(type),
            abi: claimAllAbi,
            functionName: 'claimAll',
            args: [
                stakeId
            ]
        });

        return waitForTransactionReceipt(wagmiConfig, {
            hash: claimTxHash
        });
    }
}

export const useClaimAmount = (type: StakingPool, stakeId: bigint) => {
    const { writeContractAsync } = useWriteContract();

    return async (value: bigint) => {
        const claimTxHash = await writeContractAsync({
            address: getContractAddressByType(type),
            abi: claimAmountAbi,
            functionName: 'claimAmount',
            args: [
                stakeId,
                value
            ]
        });

        return waitForTransactionReceipt(wagmiConfig, {
            hash: claimTxHash
        });
    }
}

export const useRestake = (type: StakingPool, stakeId: bigint) => {
    const { writeContractAsync } = useWriteContract();

    return async () => {
        const restakeTxHash = await writeContractAsync({
            address: getContractAddressByType(type),
            abi: restakeAbi,
            functionName: 'restake',
            args: [
                stakeId
            ]
        });

        return waitForTransactionReceipt(wagmiConfig, {
            hash: restakeTxHash
        });
    }
}

export const useClaimReferralReward = () => {
    const { writeContractAsync } = useWriteContract();

    return async (_address: string) => {
        const claim = await getClaimData();

        const referralClaimTxHash = await writeContractAsync({
            address: config.referralRewardsContractAddress,
            abi: referralClaimAbi,
            functionName: 'claim',
            args: [
                claim['receiver'],
                claim['amount'],
                String(claim['claimId']),
                String(claim['deadline']),
                claim['signature'],
            ]
        });

        return waitForTransactionReceipt(wagmiConfig, {
            hash: referralClaimTxHash
        });
    };
};

export const useAddLiquidityForReferralRewards = () => {
    const { writeContractAsync } = useWriteContract();

    return async (amount: number) => {
        const addLiquidityTxHash = await writeContractAsync({
            address: config.usdcTokenContractAddress,
            abi: erc20Abi,
            functionName: 'transfer',
            args: [
                config.referralRewardsContractAddress,
                BigInt(toUsdcNumber(amount))
            ]
        });

        return waitForTransactionReceipt(wagmiConfig, {
            hash: addLiquidityTxHash
        });
    }
}

export const useRemoveLiquidityForReferralRewards = () => {
    const { writeContractAsync } = useWriteContract();

    return async (addressTo: string, amount: number) => {
        const removeLiquidityTxHash = await writeContractAsync({
            address: config.referralRewardsContractAddress,
            abi: removeLiquidityFromReferralAbi,
            functionName: 'recoverToken',
            args: [
                config.usdcTokenContractAddress,
                addressTo,
                BigInt(toUsdcNumber(amount))
            ]
        });

        return waitForTransactionReceipt(wagmiConfig, {
            hash: removeLiquidityTxHash
        });
    }
}

export const getContractAddressByType = (type: StakingPool) => {
    switch (type) {
        case StakingPool.LIQUIDITY:
            return config.liquidityPoolContractAddress;
        case StakingPool.LOCKED_30_DAYS:
            return config.lockedPool30DaysContractAddress;
        case StakingPool.LOCKED_90_DAYS:
            return config.lockedPool90DaysContractAddress;
    }
}

const getStakesAbiByPool = (type: StakingPool) => {
    switch (type) {
        case StakingPool.LIQUIDITY:
            return getStakesAbi;
        case StakingPool.LOCKED_30_DAYS:
        case StakingPool.LOCKED_90_DAYS:
            return getStakesLockedAbi;
    }
}
