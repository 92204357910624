import { http, createConfig } from 'wagmi'
import { mainnet, sepolia } from 'wagmi/chains'
import { injected, metaMask, walletConnect} from 'wagmi/connectors'

import config from './config';

export default createConfig({
    chains: [mainnet, sepolia],
    connectors: [
        injected(),
        walletConnect({
            projectId: config.walletConnectProjectId,
            showQrModal: false
        }),
        metaMask()
    ],
    transports: {
        [mainnet.id]: http(),
        [sepolia.id]: http(config.sepoliaRpcUrl)
    }
});
