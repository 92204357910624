import React, {createContext, ReactNode, useContext, useState} from "react";

interface Stats {
    shouldUpdateStats: boolean;
    setShouldUpdateStats: (value: boolean) => void;
}

const StatsContext = createContext(null);

export function useStats() {
    return useContext<Stats>(StatsContext);
}

export function StatsProvider({ children }: { children: ReactNode }) {
    const [shouldUpdateStats, setShouldUpdateStats] = useState(false);

    return (
        <StatsContext.Provider value={{ shouldUpdateStats, setShouldUpdateStats }}>
            {children}
        </StatsContext.Provider>
    );
}