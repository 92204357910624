import React, {useEffect, useRef, useState} from "react";

interface Props {
    value: string;
    onChange: (value: string) => void;
    id: string;
    unit: string;
    isDisabled: boolean;
}

const VariableInput = ({ value, onChange, id, unit, isDisabled }: Props) => {
    const [enteredTextWidth, setEnteredTextWidth] = useState(38);
    const textRef = useRef();

    useEffect(() => {
        setEnteredTextWidth(value.length > 0 ? textRef.current.offsetWidth : 38);
    }, [value]);

    return (
        <>
            <input
                value={value}
                onChange={(event) => onChange(event.target.value)}
                type="text"
                id={id}
                className={`bg-input border-none sm:text-xl rounded-lg block w-full ps-5 p-2.5 outline-0 ${isDisabled ? "text-placeholder" : "text-neutral"}`}
                placeholder="0"
                disabled={isDisabled}
            />
            <span
                ref={textRef}
                className="absolute inset-y-0 sm:text-xl block ps-5 p-2.5 w-min overflow-auto opacity-0 -z-10"
            >
                {value}
            </span>
            <span
                className={`absolute inset-y-0 flex items-center sm:text-xl select-none ${
                    value.length === 0 || (isDisabled) ? "text-placeholder" : "text-neutral"
                }`}
                style={{left: enteredTextWidth + "px"}}
            >
                {unit}
            </span>
        </>
    );
}

export default VariableInput;