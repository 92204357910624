import React, {useEffect, useState} from "react";
import SavingVariableField from "../SavingVariableField";
import {StakingPool} from "../../../model/stakingPool";
import {
    useSaveMaxPoolAmount,
    useSaveMaxStakingAmount,
    useSaveMinStakingAmount,
    useSaveStakingPoolAPR,
    useStakingPoolInfo,
    useTotalStaked
} from "../../../helpers/contracts";
import {getReferralRewardRate, useUpdateReferralRewardRate} from "../../../api/referral";
import {formatUsdcLabel, fromUsdcNumber} from "../../../utils";
import {toast} from "react-toastify";

interface Props {
    poolType: StakingPool;
}

const StakingPoolInfoManagement = ({ poolType }: Props) => {
    const { isLoading, data } = useStakingPoolInfo(poolType);
    const { data: totalStaked } = useTotalStaked(poolType);

    const [totalStakedLabel, setTotalStakedLabel] = useState("-");
    const [totalInterestLabel, setTotalInterestLabel] = useState("-");

    const [apr, setApr] = useState("");
    const onSaveApr = useSaveStakingPoolAPR();

    const [maxPoolAmount, setMaxPoolAmount] = useState("");
    const onSaveMaxPoolAmount = useSaveMaxPoolAmount();

    const [minStakingAmount, setMinStakingAmount] = useState("");
    const onSaveMinStakingAmount = useSaveMinStakingAmount();

    const [maxStakingAmount, setMaxStakingAmount] = useState("");
    const onSaveMaxStakingAmount = useSaveMaxStakingAmount();

    const [referralReward, setReferralReward] = useState("");
    const onSaveReferralRewardRate = useUpdateReferralRewardRate();

    useEffect(() => {
        try {
            if (data && data.length > 0) {
                setApr(String(Number(data[0].result) / 100));
                setMaxPoolAmount(String(fromUsdcNumber(Number(data[1].result))));
                setMinStakingAmount(String(fromUsdcNumber(Number(data[2].result))));
                setMaxStakingAmount(String(fromUsdcNumber(Number(data[3].result))));

                setTotalInterestLabel(formatUsdcLabel(BigInt(Number(data[4].result)), 6));
            }

            if (poolType === StakingPool.LOCKED_30_DAYS || poolType === StakingPool.LOCKED_90_DAYS) {
                getReferralRewardRate(poolType)
                    .then((value) => setReferralReward(String(value * 100)));
            }
        } catch (error) {
            toast(error.message, { type: 'error' });
        }
    }, [data, poolType]);

    useEffect(() => {
        if (totalStaked !== undefined && totalStaked !== null) {
            setTotalStakedLabel(formatUsdcLabel(BigInt(Number(totalStaked)), 6));
        }
    }, [totalStaked]);

    return (
        <>
            {isLoading &&
                <div className="bill-info flex justify-center my-[30px]">
                    <p className="font-medium text-neutral">Loading...</p>
                </div>
            }
            {data && data.length > 0 &&
                <>
                    <div className="bill-info flex justify-center my-[30px]">
                        <div className="bill-info__item flex flex-col sm:text-xl text-center text-neutral font-light">
                            <span className="bill-info__name">Total staked</span>
                            <span className="bill-info__value">{totalStakedLabel}</span>
                        </div>
                        <div className="dellimiter-vertical bg-[#D9D9D9] h-[52px] w-[5px] rounded-full mx-4"></div>
                        <div className="bill-info__item flex flex-col sm:text-xl text-center text-neutral font-light">
                            <span className="bill-info__name">Total interest</span>
                            <span className="bill-info__value">{totalInterestLabel}</span>
                        </div>
                    </div>
                    <SavingVariableField
                        poolType={poolType}
                        value={apr}
                        onChange={setApr}
                        id="apy"
                        label="APY"
                        unit="%"
                        onSave={onSaveApr}
                    />
                    <SavingVariableField
                        poolType={poolType}
                        value={maxPoolAmount}
                        onChange={setMaxPoolAmount}
                        id="maxPoolAmount"
                        label="Max pool amount"
                        unit="USDC"
                        onSave={onSaveMaxPoolAmount}
                    />
                    <SavingVariableField
                        poolType={poolType}
                        value={minStakingAmount}
                        onChange={setMinStakingAmount}
                        id="minStakingAmount"
                        label="Min staking amount"
                        unit="USDC"
                        onSave={onSaveMinStakingAmount}
                    />
                    <SavingVariableField
                        poolType={poolType}
                        value={maxStakingAmount}
                        onChange={setMaxStakingAmount}
                        id="maxStakingAmount"
                        label="Max staking amount"
                        unit="USDC"
                        onSave={onSaveMaxStakingAmount}
                    />
                    {
                        (poolType === StakingPool.LOCKED_30_DAYS || poolType === StakingPool.LOCKED_90_DAYS) &&
                        <SavingVariableField
                            poolType={poolType}
                            value={referralReward}
                            onChange={setReferralReward}
                            id="referralReward"
                            label="Referral reward"
                            unit="%"
                            onSave={onSaveReferralRewardRate}
                            isStoredInContract={false}
                        />
                    }
                </>
            }
        </>
    );
}

export default StakingPoolInfoManagement;