import {useEffect, useState} from "react";
import {getToken} from "../api/client";

export const useYieldstoneSession = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(!!getToken());
    useEffect(() => {
        const onUpdate = () => {
            setIsLoggedIn(!!getToken());
        }
        window.addEventListener('storage', onUpdate);
        return () => window.removeEventListener('storage', onUpdate);
    }, [isLoggedIn]);
    return {isLoggedIn};
}
