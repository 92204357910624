import React from 'react';
import {useStakingPoolAPR} from "../../helpers/contracts";
import {StakingPool} from "../../model/stakingPool";

interface Props {
    type: StakingPool
    onLoading?: string | React.ReactNode
    onError?: string | React.ReactNode
}

const StakingPoolAPYValue = ({type, onError = '-', onLoading = '-'} : Props) => {
    const { isLoading, data, isError, error } = useStakingPoolAPR(type);

    if (isError) {
        console.error(error);
        return onError;
    }

    if (isLoading) {
        return onLoading;
    }

    return `${Number(data) / 100}%`
}

export default StakingPoolAPYValue;