import React from "react";
import {StakingPool} from "../../model/stakingPool";
import {useAccount} from "wagmi";
import StakedValue from "../../components/StakedValue";
import StakedTotalLockedValue from "../../components/StakedTotalLockedValue";
import {useStakes} from "../../helpers/contracts";
import ClaimableStakeCard from "../../components/ClaimableStakeCard";
import {LockedStaking, Staking} from "../../model/staking";
import {useStats} from "../../context/StatsContext";

const ClaimTab = () => {
    const {address, isConnected} = useAccount();

    const { isLoading, data, refetch } = useStakes(
        address,
        [StakingPool.LIQUIDITY, StakingPool.LOCKED_30_DAYS, StakingPool.LOCKED_90_DAYS]
    );

    const { setShouldUpdateStats } = useStats();

    const doOnActionSuccess = () => {
        refetch();
        setShouldUpdateStats(true);
    }

    return (
        <>
            <div className="bill-info flex justify-center mb-[25px]">
                <div
                    className="bill-info__item flex flex-col sm:text-xl text-center text-neutral font-light"
                >
                    <span className="bill-info__name">Staked</span>
                    <span className="bill-info__value">
                        {isConnected ? <StakedValue
                            address={address}
                            pools={[StakingPool.LIQUIDITY, StakingPool.LOCKED_30_DAYS, StakingPool.LOCKED_90_DAYS]}
                        /> : '-'}
                    </span>
                </div>
                <div
                    className="dellimiter-vertical bg-[#D9D9D9] h-[52px] w-[5px] rounded-full mx-4 md:mx-20"
                ></div>
                <div
                    className="bill-info__item flex flex-col sm:text-xl text-center text-neutral font-light"
                >
                    <span className="bill-info__name">Locked</span>
                    <span className="bill-info__value">{isConnected ? <StakedTotalLockedValue address={address} /> : '-'}</span>
                </div>
            </div>
            <div className="plains flex flex-row flex-wrap gap-5 justify-center">
                {isConnected && isLoading ? <p className="font-medium text-neutral">Loading...</p> : null}
                {data?.map((stakesInPoolResponse, idxOuter) => {
                    return stakesInPoolResponse.result
                        ?.filter((stake: Staking | LockedStaking) => !stake.isCompleted)
                        .map((stake, idxInner) => {
                            return (
                                <React.Fragment key={`${idxOuter}-${idxInner}`}>
                                    <ClaimableStakeCard stake={stake} type={StakingPool[StakingPool[idxOuter]]} onActionSuccess={doOnActionSuccess}/>
                                </React.Fragment>
                            );
                        })
                })}
            </div>
        </>
    );
}

export default ClaimTab;