import React, {useRef, useState} from "react";
import {createPortal} from "react-dom";
import AmountInput from "../AmountInput";
import ActionButton from "../ActionButton";
import {toast} from "react-toastify";
import {toUsdcBigInt} from "../../utils";

interface Props {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  availableToClaim: bigint;
  onSubmit: (value: bigint) => void;
}

export const ModalOptions = ({ setShowModal, showModal, availableToClaim, onSubmit }: Props) => {
  const modalRef = useRef();
  const [amountToStake, setAmountToStake] = useState("");

  return createPortal(
    <div
      className={`modal relative z-10 ${showModal ? "" : "hidden"}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-background opacity-50 transition-opacity"
        aria-hidden="true"
      ></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            ref={modalRef}
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-8 bg-background"
          >
            <svg
              className="absolute top-4 right-4 cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 25 25"
              fill="none"
              onClick={() => {
                setShowModal(false);
              }}
            >
              <path
                fill="#fff"
                d="m24.678 20.37-8.2-8.226 8.219-8.121a.867.867 0 0 0 0-1.224L22.359.449A.86.86 0 0 0 21.747.2a.887.887 0 0 0-.613.25l-8.18 8.096L4.758.456a.86.86 0 0 0-.612-.25.887.887 0 0 0-.613.25l-2.33 2.349a.867.867 0 0 0 0 1.224l8.218 8.121-8.194 8.22a.865.865 0 0 0-.256.613c0 .231.087.45.256.612l2.338 2.349a.862.862 0 0 0 1.225 0l8.162-8.196 8.169 8.19a.862.862 0 0 0 1.225 0l2.337-2.35a.865.865 0 0 0 .257-.611.896.896 0 0 0-.263-.606Z"
              />
            </svg>
            <h3 className="text-2xl text-neutral mb-4">
              Enter the amount to claim:
            </h3>
            <AmountInput
              max={availableToClaim}
              value={amountToStake}
              onChange={(value) => setAmountToStake(value)}
            />
            <ActionButton
              className="mt-4 mx-auto"
              onClick={() => {
                try {
                  onSubmit(toUsdcBigInt(Number(amountToStake)));
                } catch (error) {
                  if (error instanceof SyntaxError) {
                    toast('Wrong amount format.', { type: 'error'  });
                  } else {
                    toast('Unexpected error.', { type: 'error'  });
                  }
                }
                setShowModal(false);
              }}
            >
              Submit
            </ActionButton>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ModalOptions;
