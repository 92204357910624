import "./App.scss";
import {useState} from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createWeb3Modal, useWeb3Modal } from "@web3modal/wagmi/react";
import {useAccountEffect, WagmiProvider, useSignMessage} from "wagmi";
import wagmiConfig from "./config/wagmiConfig";
import config from "./config/config";
import logo from "./images/logo.png";
import { ConnectWalletButton } from "./components/ConnectWalletButton/ConnectWalletButton";
import StakeTab from "./tabs/StakeTab";
import ClaimTab from "./tabs/ClaimTab";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ReferralTab from "./tabs/ReferralTab";
import {getNonce, login} from "./api/auth";
import {isLoggedIn, removeToken, storeToken} from "./api/client";
import {addNewReferral, getReferrerCode} from "./api/referral";
import AdminTab from "./tabs/AdminTab";
import {StatsProvider} from "./context/StatsContext";
import LiquidityStorageTab from "./components/AdminPage/LiquidityStorageTab";
import {useAdmin, useLiquiditySource} from "./helpers/admin";

const withProviders =
    (WrappedComponent) =>
        ({ props }) => {
            createWeb3Modal({
                wagmiConfig,
                projectId: config.walletConnectProjectId,
            });

            const queryClient = new QueryClient({
                defaultOptions: {
                    queries: {
                        staleTime: 0
                    }
                }
            });

            return (
                <WagmiProvider config={wagmiConfig}>
                    <QueryClientProvider client={queryClient}>
                        <WrappedComponent {...props} />
                    </QueryClientProvider>
                </WagmiProvider>
            );
        };

function App() {
    // useWeb3Modal must be called only after createWeb3Modal
    const {open} = useWeb3Modal();
    const { signMessageAsync } = useSignMessage();
    const isAdmin = useAdmin();
    const isLiquiditySource = useLiquiditySource();

    useAccountEffect({
        async onConnect(data) {
            if (data.isReconnected) {
                console.debug('reconnected, is stored access token', isLoggedIn());
                return;
            }
            console.debug('connected, logining')
            const address = data.address;
            if (address !== config.liquiditySourceAddress) {
                const nonceResponse = await getNonce(address);
                const signature = await signMessageAsync({message: 'Connecting wallet to YieldStone, nonce: ' + nonceResponse.nonce});
                const accessTokenResponse = await login({address, signature});
                console.debug('login success, storing access token');
                storeToken(accessTokenResponse.access_token);
                const referrer = getReferrerCode();
                if (referrer) {
                    try {
                        await addNewReferral(referrer);
                    } catch (e) {
                        // ignoring error for this request
                        console.debug('this error ignored', e);
                    }
                }
            }
        },
        onDisconnect(data) {
            console.debug('disconnecting wallet connect, removing access token');
            removeToken();
            setActiveTab(0);
        }
    });

    const onLoginClick = () => {
        open();
    };

    const [activeTab, setActiveTab] = useState(0);

    const items = [];

    if (isLiquiditySource) {
        items.push({title: "Liquidity storage", tabContent: <LiquidityStorageTab/>});
    } else {
        items.push({title: "Stake", tabContent: <StakeTab/>});
        items.push({title: "Claim", tabContent: <ClaimTab/>});
        items.push({title: "Referral program", tabContent: <ReferralTab />});

        if (isAdmin) {
            items.push({title: "Admin page", tabContent: <AdminTab/>});
        }
    }

    return (
        <>
            <div className="bg-background min-h-screen px-6 pt-[1px] pb-6 xl:px-10 xl:pb-10 lg:px-8 lg:pb-8">
                <div className="relative menu mx-auto pt-6 pb-4">
                    <div className="flex gap-10 content-center flex-row flex-wrap justify-center">
                        <a
                            href="https://www.yieldstone.io/"
                        >
                            <img
                                className="logo w-full md:h-10 h-8 object-contain"
                                src={logo}
                                alt="logo"
                            />
                        </a>
                        <div className="action-buttons md:ml-auto shrink-0 flex gap-5 md:gap-10">
                            <a
                                href="https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
                                target="_blank"
                                rel="noreferrer"
                                className="bg-neutral hover:bg-accent text-secondary font-normal py-[10px] px-6 xs:px-9 rounded-full cursor-pointer border-solid border-1 border-transparent"
                            >
                                Swap
                            </a>
                            <ConnectWalletButton onClick={onLoginClick}/>
                        </div>
                    </div>
                </div>

                <div className="container mx-auto mt-5 flex flex-col">
                    <div className="tab-nav flex gap-4 justify-center mb-[33px] items-center md:flex-nowrap flex-wrap">
                        {items.map((tabItem, indexTab) => (
                            <button
                                key={"tab-" + indexTab}
                                data-index={indexTab}
                                className={`xs:text-xl text-sm text-neutral font-normal py-[10px] px-6 md:px-[55px] rounded-xl cursor-pointer tracking-[0.03em] ${
                                    indexTab === activeTab ? "bg-primary" : "bg-surface"
                                }`}
                                onClick={() => setActiveTab(indexTab)}
                            >
                                {tabItem.title}
                            </button>
                        ))}
                    </div>
                    <StatsProvider>
                        <div className="bg-surface rounded-[24px] px-5 md:px-20 py-[50px] w-full max-w-[900px] mx-auto">
                            {items[activeTab].tabContent}
                        </div>
                    </StatsProvider>
                </div>
            </div>
            <ToastContainer theme={'dark'}/>
        </>
    );
}

export default withProviders(App);
