import React, {useEffect} from "react";
import {useUsdcBalance} from "../../helpers/wagmi";
import {formatUsdcLabel} from "../../utils";
import {useStats} from "../../context/StatsContext";

interface Props {
    address: string;
    onLoading?: string | React.ReactNode;
    onError?: string | React.ReactNode;
    onUpdate?: (value: bigint) => void;
}

const UsdcBalanceValue = ({ address, onError = '-', onLoading = '-', onUpdate }: Props) => {
    const { isLoading, data, isError, error, refetch } = useUsdcBalance(address);
    const { shouldUpdateStats, setShouldUpdateStats } = useStats();

    useEffect(() => {
        if (onUpdate) {
            onUpdate(data ?? 0n);
        }
    }, [data, onUpdate]);

    useEffect(() => {
        if (shouldUpdateStats) {
            refetch();
            setShouldUpdateStats(false);
        }
    }, [shouldUpdateStats, setShouldUpdateStats, refetch]);

    if (isError) {
        console.error(error);
        return onError;
    }

    if (isLoading) {
        return onLoading;
    }

    return formatUsdcLabel(data);
}

export default UsdcBalanceValue;