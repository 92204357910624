import React, {useEffect, useState} from "react";
import {useAccount} from "wagmi";
import {useContractOwnerByPoolType} from "../../../helpers/contracts";
import {StakingPool} from "../../../model/stakingPool";
import VariableInput from "../VariableInput";

interface Props {
    children: React.ReactNode;
    poolType: StakingPool;
    value: string;
    onChange: (newValue: string) => void;
    id: string;
    label: string;
    unit: string;
    isStoredInContract?: boolean;
}

const VariableField = ({ children, poolType, value, onChange, id, label, unit, isStoredInContract = true }: Props) => {
    const { address } = useAccount();
    const { data } = useContractOwnerByPoolType(poolType);
    const [isOwner, setIsOwner] = useState<boolean>(false);

    useEffect(() => {
        setIsOwner(address === data);
    }, [address, data]);

    return (
        <div className="flex flex-col md:flex-row gap-2 md:gap-4 mt-5">
            <div className="md:w-4/12 flex justify-between">
                <label className="block text-neutral sm:text-lg font-normal bold mt-2" htmlFor={id}>
                    {label}
                </label>
                {isStoredInContract &&
                    <span className="inline-block pt-2 text-neutral">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" strokeWidth={1}
                             stroke="currentColor" className="size-5">
                            <path
                                d="M3 3.5A1.5 1.5 0 0 1 4.5 2h6.879a1.5 1.5 0 0 1 1.06.44l4.122 4.12A1.5 1.5 0 0 1 17 7.622V16.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 3 16.5v-13Z"/>
                        </svg>
                    </span>
                }
            </div>
            <div className="md:w-4/12 relative">
                <VariableInput value={value} onChange={onChange} id={id} unit={unit} isDisabled={isStoredInContract && !isOwner} />
            </div>
            <div className="md:w-4/12 flex justify-start">
                {(!isStoredInContract || isOwner) && children}
            </div>
        </div>
    );
};

export default VariableField;