import React, {useEffect, useState} from "react";
import {useAddLiquidity, useRemoveLiquidity} from "../../../helpers/contracts";
import VariableInput from "../VariableInput";
import {toast} from "react-toastify";
import {toUsdcNumber} from "../../../utils";
import {useLiquiditySource} from "../../../helpers/admin";
import * as Sentry from "@sentry/react";

interface Props {
    onUpdate: () => void;
}

const LiquidityVariableField = ({ onUpdate }: Props) => {
    const isLiquiditySource = useLiquiditySource();
    const [value, setValue] = useState("");

    const [isAdding, setIsAdding] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        setIsDisabled(isAdding || isRemoving);
    }, [isAdding, isRemoving]);

    const doAddLiquidity = useAddLiquidity();
    const doRemoveLiquidity = useRemoveLiquidity();

    const onActionClick = (
        setActive: (isActive: boolean) => void,
        doAction: (amount: bigint) => Promise<any>
    ) => {
        if (!value || isNaN(Number(value))) {
            toast('Wrong number format.', {type: 'error'});
            return;
        }
        setActive(true);

        doAction(
            BigInt(toUsdcNumber(Number(value)))
        )
            .then(() => {
                toast("Please, check transaction in Safe Wallet", {type: 'success'});
                onUpdate?.();
                Sentry.captureMessage("Please, check transaction in Safe Wallet", "info");
            })
            .catch((error) => {
                console.error(error);
                toast(error.shortMessage, {type: 'error'});
            })
            .finally(() => {
                setActive(false);
            });
    }

    const onSaveClick = () => {
        onActionClick(
            setIsAdding,
            doAddLiquidity
        );
    }

    const onRemoveClick = () => {
        onActionClick(
            setIsRemoving,
            doRemoveLiquidity
        );
    }

    return (
        <div className="flex flex-col md:flex-row gap-2 md:gap-4 mt-5">
            <div className="md:w-4/12 flex justify-between">
                <label className="block text-neutral sm:text-lg font-normal bold mt-2" htmlFor="liquidity">
                    Liquidity
                </label>
            </div>
            <div className="md:w-4/12 relative">
                <VariableInput value={value} onChange={setValue} id="liquidity" unit="USDC" isDisabled={!isLiquiditySource || isDisabled}/>
            </div>
            <div className="md:w-4/12 flex justify-start">
                {isLiquiditySource &&
                    <>
                        <button
                            className={`inline-block sm:text-base font-normal py-2 px-2 rounded-full mx-1 w-[105px] ${value === "" || isDisabled
                                ? "bg-disabled text-inactive pointer-events-none"
                                : "bg-accent text-secondary"}`}
                            onClick={onSaveClick}
                        >
                            {isAdding ? "Adding..." : "Add"}
                        </button>
                        <button
                            className={`inline-block sm:text-base font-normal py-2 px-2 rounded-full mx-1 w-[105px] ${value === "" || isDisabled
                                ? "bg-disabled text-inactive pointer-events-none"
                                : "bg-neutral text-secondary"}`}
                            onClick={onRemoveClick}
                        >
                            {isRemoving ? "Removing..." : "Remove"}
                        </button>
                    </>
                }
            </div>
        </div>
    );
}

export default LiquidityVariableField;