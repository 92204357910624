import React, {useState} from "react";

interface Props {
    content: string;
}

const Tooltip = ({ content }: Props) => {
    const [show, setShow] = useState(false);

    return (
        <span className="relative">
            <svg
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                stroke="currentColor" className="size-6 ml-1 text-disabled">
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"/>
            </svg>
            <span
                role="tooltip"
                className={`absolute bottom-7 mt-2 z-10 inline-block px-3 py-2 text-sm font-medium bg-secondary text-neutral rounded-lg shadow-sm max-w-48 w-max ${show ? "" : "hidden"}`}
            >
                {content}
            </span>
        </span>
    );
}

export default Tooltip;