import config from '../config/config';
import axios, {InternalAxiosRequestConfig} from "axios";

export const url = (path: string): string => `${config.backendUrl}${path}`;
export const storeToken = (token: string): void => {
    localStorage.setItem('token', token);
    window.dispatchEvent(new Event('storage'));
}
export const removeToken = (): void => {
    localStorage.removeItem('token');
    window.dispatchEvent(new Event('storage'));
}
export const getToken = (): string => localStorage.getItem('token');
export const isLoggedIn = (): boolean => !!getToken()

axios.defaults.headers['Content-Type'] = 'application/json';

const instance = axios.create({
    headers: {
        'Content-Type': 'application/json'
    }
});

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    config.headers['authorization'] = `Bearer ${getToken()}`;
    return config;
});

export const client = instance;
