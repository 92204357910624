import React, {useEffect, useState} from "react";
import {useLiquidity, useTotalInterests} from "../../../helpers/contracts";
import {useUsdcBalance} from "../../../helpers/wagmi";
import config from '../../../config/config';
import {formatUsdcLabel} from "../../../utils";
import LiquidityVariableField from "../LiquidityVariableField";
import Tooltip from "../../Tooltip";

const LiquidityStorageTab = () => {
    const { data: balanceData, refetch: refetchBalanceData } = useUsdcBalance(config.liquidityStorageContractAddress);
    const { data: liquidityData, refetch: refetchLiquidityData } = useLiquidity();
    const { data: totalInterestsData, refetch: refetchTotalInterestsData } = useTotalInterests();

    const [balanceLabel, setBalanceLabel] = useState("-");
    const [liquidityLabel, setLiquidityLabel] = useState("-");
    const [liquidityReserveLabel, setLiquidityReserveLabel] = useState("-");

    useEffect(() => {
        if (balanceData !== undefined && balanceData !== null) {
            setBalanceLabel(formatUsdcLabel(BigInt(Number(balanceData)), 6));
        }
    }, [balanceData]);

    useEffect(() => {
        if (liquidityData !== undefined && liquidityData !== null) {
            const liquidity = BigInt(Number(liquidityData));
            setLiquidityLabel(formatUsdcLabel(liquidity, 6));

            if (totalInterestsData && totalInterestsData.length > 0) {
                const totalInterests = totalInterestsData.reduce(
                    (accumulator, currentValue) => accumulator + BigInt(Number(currentValue.result)),
                    0n
                );
                const liquidityReserve = liquidity - totalInterests;
                setLiquidityReserveLabel(formatUsdcLabel(liquidityReserve, 6));
            }
        }
    }, [liquidityData, totalInterestsData]);

    const onLiquidityUpdate = () => {
        refetchBalanceData?.();
        refetchLiquidityData?.();
        refetchTotalInterestsData?.();
    }

    return (
        <div className="plain-card bg-card p-4 rounded-[15px] w-full">
            <div className="flex flex-col md:flex-row gap-4 md:gap-8">
                <h3 className="plain-card__name sm:text-[22px] text-neutral font-medium">Liquidity</h3>
            </div>
            <div className="bill-info flex justify-center my-[30px]">
                <div className="bill-info__item flex flex-col sm:text-xl text-center text-neutral font-light">
                    <span className="bill-info__name flex items-center justify-center">
                        Balance
                        <Tooltip content="Total balance including staked amount across all the pools and liquidity."/>
                    </span>
                    <span className="bill-info__value">{balanceLabel}</span>
                </div>
                <div className="dellimiter-vertical bg-[#D9D9D9] h-[52px] w-[5px] rounded-full mx-4"></div>
                <div className="bill-info__item flex flex-col sm:text-xl text-center text-neutral font-light">
                    <span className="bill-info__name flex items-center justify-center">
                        Liquidity
                        <Tooltip content="Liquidity added to the liquidity storage. Can be negative if the liquidity is withdrawn along with the staked amount."/>
                    </span>
                    <span className="bill-info__value">{liquidityLabel}</span>
                </div>
                <div className="dellimiter-vertical bg-[#D9D9D9] h-[52px] w-[5px] rounded-full mx-4"></div>
                <div className="bill-info__item flex flex-col sm:text-xl text-center text-neutral font-light">
                    <span className="bill-info__name flex items-center justify-center">
                        Liquidity reserve
                        <Tooltip content="Reserve to cover staked amounts along with interests. Difference between liquidity and total interest across all the pools."/>
                    </span>
                    <span className="bill-info__value">{liquidityReserveLabel}</span>
                </div>
            </div>
            <LiquidityVariableField onUpdate={onLiquidityUpdate}/>
        </div>
    );
}

export default LiquidityStorageTab;