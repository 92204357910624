import {useEffect, useState} from "react";
import {StakingPool} from "../../model/stakingPool";
import {getReferralRewardRate} from "../../api/referral";
import {toast} from "react-toastify";
import {numberFormat} from "../../utils";

interface Props {
    type: StakingPool;
}

const ReferralRewardPercentage = ({ type }: Props) => {
    const [rate, setRate] = useState<null | number>(null);

    useEffect(() => {
        try {
            getReferralRewardRate(type).then(setRate);
        } catch (error) {
            toast(error.message, { type: 'error' });
        }
    }, [setRate, type]);

    if (!rate) {
        return '-';
    }

    return `${numberFormat.format(rate * 100)}%`
}

export default ReferralRewardPercentage;
