import React, {useEffect, useRef, useState} from "react";
import {fromUsdcNumber} from "../../utils";

interface Props {
    value: string;
    className?: string;
    max: bigint;
    onChange: (newValue: string) => void;
    disabled?: boolean;
}

const AmountInput = ({className, max, onChange, value, disabled = false}: Props) => {
    const [enteredTextWidth, setEnteredTextWidth] = useState(38);
    const textRef = useRef();

    useEffect(() => {
        if (value.length > 0) {
            setEnteredTextWidth(textRef.current.offsetWidth);
        } else {
            setEnteredTextWidth(38);
        }
    }, [value]);

    return (
        <div className={`relative ${className}`}>
            <div
                className={`absolute inset-y-0 end-5 flex items-center ps-3.5 text-placeholder select-none ${!disabled ? 'hover:text-neutral cursor-pointer' : ''}`}>
      <span
          className="sm:text-xl"
          onClick={() => {
              if (!disabled) {
                  onChange(String(fromUsdcNumber(Number(max))));
              }
          }}
      >
        Max
      </span>
            </div>
            <input
                value={value}
                onChange={(event) => onChange(event.target.value)}
                type="text"
                id="input-group-1"
                className="bg-input border-none text-neutral sm:text-xl rounded-lg block w-full ps-5 p-2.5 outline-0"
                placeholder="0"
                disabled={disabled}
            />
            <span
                ref={textRef}
                id="value"
                className="absolute inset-y-0 sm:text-xl block ps-5 p-2.5 w-min overflow-auto opacity-0 -z-10"
            >
      {value}
    </span>

            <span
                className={`absolute inset-y-0 flex items-center sm:text-xl select-none ${
                    value.length === 0 ? "text-placeholder" : "text-neutral"
                }`}
                style={{left: enteredTextWidth + "px"}}
            >
      USDC
    </span>
        </div>
    );
};

export default AmountInput;